<div class="container">

  <mat-card>

    <form #accountFormRef [formGroup]="glAccountForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>Account Type</mat-label>
            <mat-select required formControlName="type">
              <mat-option *ngFor="let accountType of accountTypeData" [value]="accountType.id">
                {{ accountType.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="glAccountForm.controls.type.hasError('required')">
              Account Type is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Account Name</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="glAccountForm.controls.name.hasError('required')">
              Account Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Account Usage</mat-label>
            <mat-select required formControlName="usage">
              <mat-option *ngFor="let accountUsage of accountUsageData" [value]="accountUsage.id">
                {{ accountUsage.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="glAccountForm.controls.usage.hasError('required')">
              Account Usage is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>GL Code</mat-label>
            <input matInput required formControlName="glCode">
            <mat-error *ngIf="glAccountForm.controls.glCode.hasError('required')">
              GL Code is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Parent</mat-label>
            <mat-select formControlName="parentId">
              <mat-option *ngFor="let parent of parentData" [value]="parent.id">
                ({{ parent.glCode }}) {{ parent.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="28%">
            <mat-label>Tag</mat-label>
            <mat-select formControlName="tagId">
              <mat-option *ngFor="let tag of tagData" [value]="tag.id">
                {{ tag.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div fxFlex="18%" class="manual-entries-allowed-wrapper">
            <mat-checkbox labelPosition="before" required formControlName="manualEntriesAllowed" class="manual-entries-allowed">
              Manual Entries Allowed
            </mat-checkbox>
          </div>

          <mat-form-field fxFlex="98%">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="[cancelRoute]">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!glAccountForm.valid" *mifosxHasPermission="'CREATE_GLACCOUNT'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateAccountFormRef let-popover="popover">
  <h2>Create GL account</h2>
  <p class="mw400">Click to start filling the details. * mark fields are neccessary. For more details click: <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/67141745/Chart+of+Accounts+-+General+Ledger+Setup" target="_blank">Chart of Accounts</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close()">Create GL Account</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">Next</button>
  </div>
</ng-template>
