<div class="tab-container mat-typography">

  <router-outlet>

    <h3>Family Members</h3>

    <div fxLayout="row" fxLayoutAlign="flex-end">
      <button mat-raised-button color="primary" [routerLink]="['./add']">
        <fa-icon icon="plus" class="m-r-10"></fa-icon>Add
      </button>
    </div>

    <mat-accordion>

      <mat-expansion-panel *ngFor="let member of clientFamilyMembers;index as i" class="family-member">

        <mat-expansion-panel-header>
          <mat-panel-title>
            {{member.firstName+' '+member.middleName+' '+member.lastName}}
          </mat-panel-title>
          <mat-panel-description>
            {{member.relationship}}
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-divider [inset]="true"></mat-divider>

        <div class="family-member-actions" fxLayout="row" fxLayoutAlign="flex-end">
          <button mat-button color="primary">
            <fa-icon icon="edit" [routerLink]="[member.id,'edit']"></fa-icon>
          </button>
          <button mat-button color="warn" (click)="deleteFamilyMember(member.clientId,member.id,member.firstName,i)">
            <fa-icon icon="trash"></fa-icon>
          </button>
        </div>

        <p>
          First Name : {{member.firstName}}<br />
          Middle Name : {{member.middleName}}<br />
          Last Name : {{member.lastName}}<br />
          Qualification : {{member.qualification}}<br />
          Mobile Number : {{member.mobileNumber}}<br />
          Age : {{member.age}}<br />
          Is Dependent : {{member.isDependent}}<br />
          Martial Status : {{member.maritalStatus}}<br />
          Gender : {{member.gender}}<br />
          Profession : {{member.profession}}<br />
          Date Of Birth : {{member.dateOfBirth  | dateFormat}}<br />
        </p>

      </mat-expansion-panel>

    </mat-accordion>

  </router-outlet>

</div>
