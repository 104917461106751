/** Angular Imports */
import { Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SystemService } from '../../../system.service';

/**
 * View Scheduler Job component.
 */
@Component({
  selector: 'mifosx-view-scheduler-job',
  templateUrl: './view-scheduler-job.component.html',
  styleUrls: ['./view-scheduler-job.component.scss']
})
export class ViewSchedulerJobComponent {

  /** Job Data. */
  jobData: any;

  /**
   * Retrieves the selected job data from `resolve`.
   * @param {ActivatedRoute} route Activated Route.
   */
  constructor(private route: ActivatedRoute,
    private systemService: SystemService) {
    this.route.data.subscribe((data: { selectedJob: any }) => {
      this.jobData = data.selectedJob;
    });
  }
  runJob(): void {
    this.systemService.runSelectedJob(this.jobData.jobId);
  }

}
