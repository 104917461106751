<div fxLayout="column" id="footer" class="m-b-20">
  <mat-divider fxFlexAlign="center" class="divider"></mat-divider>
  <p fxFlexAlign="center" class="footer-content">{{ 'labels.texts.Version' | translate }} <b>{{ version }}</b> - <b>{{
      hash }}</b></p>
  <p fxFlexAlign="center" class="footer-content" *ngIf="isBusinessDateDefined">Current Business Date: <b>{{ businessDate
      | date: 'EEEE, MMMM dd, y' }}</b></p>
  <p fxFlexAlign="center" class="footer-content"><b>{{ server }}</b></p>
  <mat-divider fxFlexAlign="center" class="divider"></mat-divider>
  <p fxFlexAlign="center" class="footer-content">{{ 'APP_NAME' | translate }} {{ 'labels.texts.by' | translate }} Mifos
  </p>
</div>
