<div class="tab-content mat-typography">

  <mat-list>

    <mat-list-item>
      Campaign Name : {{ campaign.campaignName }}
    </mat-list-item>

    <mat-list-item>
      SMS Provider : {{ (campaign.providerId | find:smsProviders:'id':'name') || "Unassigned" }}
    </mat-list-item>

    <mat-list-item>
      Trigger Type : {{ (campaign.editFlag ? campaign.triggerType.id : campaign.triggerType) | find:triggerTypes:'id':'value' }}
    </mat-list-item>

    <mat-list-item>
      Bussiness Rule : {{ campaign.editFlag ? campaign.reportName : campaign.paramValue?.reportName }}
    </mat-list-item>

    <div fxLayout="column" fxLayoutGap="10px" class="template-message">
      <h3>Campaign Message :</h3>
      <textarea matInput disabled>{{ campaign.editFlag ? editedCampaignMessage : campaign.message }}</textarea>
    </div>

  </mat-list>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button [routerLink]="['../']">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="submit.emit()">
      Submit
    </button>
  </div>

</div>
