<div class="container">

  <mat-card>

    <form [formGroup]="journalEntryForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div #createJournalFormRef fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>Office</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="journalEntryForm.controls.officeId.hasError('required')">
              Office is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Currency</mat-label>
            <mat-select required formControlName="currencyCode">
              <mat-option *ngFor="let currency of currencyData" [value]="currency.code">
                {{ currency.displayLabel }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="journalEntryForm.controls.currencyCode.hasError('required')">
              Currency is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" formArrayName="debits"
            *ngFor="let debit of debits.controls; let i = index;">

            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" [formGroupName]="i">

              <mat-form-field fxFlex="48%">
                <mat-label>Affected GL Entry (Debit)</mat-label>
                <mat-select required formControlName="glAccountId">
                  <mat-option *ngFor="let affectedGLEntryDebit of glAccountData" [value]="affectedGLEntryDebit.id">
                    {{ affectedGLEntryDebit.name + ' (' + affectedGLEntryDebit.glCode + ')' }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="debits.at(i).controls.glAccountId.hasError('required')">
                  Affected GL Entry (Debit) is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="43%">
                <mat-label>Debit Amount</mat-label>
                <input type="number" matInput required formControlName="amount">
                <mat-error *ngIf="debits.at(i).controls.amount.hasError('required')">
                  Debit Amount is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <span fxFlex>
                <button *ngIf="i !== 0" type="button" mat-icon-button (click)="removeAffectedGLEntry(debits, i)">
                  <fa-icon icon="minus-circle" size="lg"></fa-icon>
                </button>
                <button *ngIf="i === 0" type="button" mat-icon-button color="primary" (click)="addAffectedGLEntry(debits)">
                  <fa-icon icon="plus-circle" size="lg"></fa-icon>
                </button>
              </span>

            </div>

          </div>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" formArrayName="credits"
            *ngFor="let credit of credits.controls; let i = index;">

            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" [formGroupName]="i">

              <mat-form-field fxFlex="48%">
                <mat-label>Affected GL Entry (Credit)</mat-label>
                <mat-select required formControlName="glAccountId">
                  <mat-option *ngFor="let affectedGLEntryCredit of glAccountData" [value]="affectedGLEntryCredit.id">
                    {{ affectedGLEntryCredit.name + ' (' + affectedGLEntryCredit.glCode + ')' }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="credits.at(i).controls.glAccountId.hasError('required')">
                  Affected GL Entry (Credit) is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="43%">
                <mat-label>Credit Amount</mat-label>
                <input type="number" matInput required formControlName="amount">
                <mat-error *ngIf="credits.at(i).controls.amount.hasError('required')">
                  Credit Amount is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <span fxFlex>
                <button *ngIf="i !== 0" type="button" mat-icon-button (click)="removeAffectedGLEntry(credits, i)">
                  <fa-icon icon="minus-circle" size="lg"></fa-icon>
                </button>
                <button *ngIf="i === 0" type="button" mat-icon-button color="primary" (click)="addAffectedGLEntry(credits)">
                  <fa-icon icon="plus-circle" size="lg"></fa-icon>
                </button>
              </span>

            </div>

          </div>

          <mat-form-field fxFlex="48%">
            <mat-label>Reference Number</mat-label>
            <input matInput formControlName="referenceNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="transactionDatePicker.open()">
            <mat-label>Transaction Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="transactionDatePicker" required formControlName="transactionDate">
            <mat-datepicker-toggle matSuffix [for]="transactionDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #transactionDatePicker></mat-datepicker>
            <mat-error *ngIf="journalEntryForm.controls.transactionDate.hasError('required')">
              Transaction Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Payment Type</mat-label>
            <mat-select formControlName="paymentTypeId">
              <mat-option *ngFor="let paymentType of paymentTypeData" [value]="paymentType.id">
                {{ paymentType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Account Number</mat-label>
            <input matInput formControlName="accountNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Cheque Number</mat-label>
            <input matInput formControlName="checkNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Routing Code</mat-label>
            <input matInput formControlName="routingCode">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Receipt Number</mat-label>
            <input matInput formControlName="receiptNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Bank Number</mat-label>
            <input matInput formControlName="bankNumber">
          </mat-form-field>

          <mat-form-field fxFlex="98%">
            <mat-label>Comments</mat-label>
            <textarea matInput formControlName="comments" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!journalEntryForm.valid" *mifosxHasPermission="'CREATE_JOURNALENTRY'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateCreateJournalFormRef let-popover="popover">
  <h2>Add Journal Entry Form</h2>
  <p class="mw300">Fields with a red asterisk (*) are required. To know more click: <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/67895310/Add+Journal+Entries" target="_blank">Add Journal Entries</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">Next</button>
  </div>
</ng-template>
