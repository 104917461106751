<div class="container space">

  <div class="mat-elevation-z8">
    <div *ngIf="!isBusinessDateEnabled">
      <p>The Business Date functionality is not enabled. If you need that, Go to Global Configurations and enable the
        configuration: <code>{{configurationName}}</code></p>
    </div>

    <div *ngIf="isBusinessDateEnabled">

      <mat-card>
        <form [formGroup]="businessDateForm">
          <mat-card-content>
            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" class="space">
              <div fxFlex="50%">
                <span fxFlex="40%" class="date-type">User date format: </span>
                <span fxFlex="60%" class="date-value">{{userDateFormat}}</span>
              </div>
            </div>

            <!-- Business Date -->
            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">
              <div fxFlex="50%" *ngIf="!isEditInProgress">
                <span fxFlex="40%" class="date-type">Business Date: </span>
                <span fxFlex="60%" class="date-value">{{businessDate | dateFormat}}</span>
              </div>

              <mat-form-field (click)="businessDatePicker.open()" *ngIf="isEditInProgress && (dateIndex == 0)">
                <mat-label>Business Date</mat-label>
                <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="businessDatePicker"
                  formControlName="businessDate">
                <mat-datepicker-toggle matSuffix [for]="businessDatePicker">
                </mat-datepicker-toggle>
                <mat-datepicker #businessDatePicker></mat-datepicker>
                <mat-error *ngIf="businessDateForm.controls.businessDate.hasError('required')">
                  Business Date is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <div fxLayoutGap="2%" fxFlex="22%" class="options-wrapper">
                <span *ngIf="isEditInProgress && (dateIndex == 0)">
                  <button mat-raised-button color="primary" [disabled]="businessDateForm.pristine" (click)="submit()"
                    *mifosxHasPermission="'UPDATE_BUSINESSDATE'">
                    Submit
                  </button>
                  <button mat-raised-button (click)="editInProgressToggle(0)">
                    Cancel
                  </button>
                </span>
                <button type="button" color="primary" *ngIf="!isEditInProgress" mat-icon-button matTooltip="Edit the Business Date"
                  matTooltipPosition="above" (click)="editInProgressToggle(0)">
                  <fa-icon icon="edit" size="lg"></fa-icon>
                </button>
              </div>
            </div>

            <!-- COB Date -->
            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">
              <div fxFlex="50%" *ngIf="!isEditInProgress">
                <span fxFlex="40%" class="date-type">COB Date: </span>
                <span fxFlex="60%" class="date-value">{{cobDate | dateFormat}}</span>
              </div>

              <mat-form-field (click)="cobDatePicker.open()" *ngIf="isEditInProgress && (dateIndex == 1)">
                <mat-label>COB Date</mat-label>
                <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="cobDatePicker"
                  formControlName="businessDate">
                <mat-datepicker-toggle matSuffix [for]="cobDatePicker">
                </mat-datepicker-toggle>
                <mat-datepicker #cobDatePicker></mat-datepicker>
                <mat-error *ngIf="businessDateForm.controls.cobDate.hasError('required')">
                  COB Date is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <div fxLayoutGap="4%" fxFlex="22%" class="options-wrapper">
                <span *ngIf="isEditInProgress && (dateIndex == 1)">
                  <button mat-stroked-button color="primary" [disabled]="businessDateForm.pristine" (click)="submit()"
                    *mifosxHasPermission="'UPDATE_BUSINESSDATE'">
                    Submit
                  </button>
                </span>
                <button mat-button *ngIf="isEditInProgress && (dateIndex == 1)" (click)="editInProgressToggle(1)">
                  Cancel
                </button>
                <!-- <button type="button" color="primary" *ngIf="!isEditInProgress" mat-icon-button matTooltip="Edit the COB Date"
                  matTooltipPosition="above" (click)="editInProgressToggle(1)">
                  <fa-icon icon="edit" size="lg"></fa-icon>
                </button> -->
              </div>
            </div>
          </mat-card-content>
        </form>
      </mat-card>
    </div>
  </div>
</div>
