<!-- TODO: Responsiveness/design enhancement and reduction in form loading time -->
<div class="container">

  <mat-card>

    <form [formGroup]="openingBalancesForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div #searchFormRef fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="88%">
            <mat-label>Office</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="openingBalancesForm.controls.officeId.hasError('required')">
              Office is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <div>
            <button mat-button type="button" color="primary" fxFlex="8%" (click)="retrieveOpeningBalances()">Retrieve</button>
          </div>

        </div>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" *ngIf="openingBalancesData">

          <label fxFlex="98%" class="content"><span class="header">Opening Balances Contra Account:</span>&nbsp;&nbsp;{{ openingBalancesData.contraAccount.name + ' (' + openingBalancesData.contraAccount.glCode + ')' }}</label>

          <mat-form-field fxFlex="48%">
            <mat-label>Currency</mat-label>
            <mat-select required formControlName="currencyCode">
              <mat-option *ngFor="let currency of currencyData" [value]="currency.code">
                {{ currency.displayLabel }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="openingBalancesForm.controls.currencyCode.hasError('required')">
              Currency is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="openingBalancesDatePicker.open()">
            <mat-label>Opening Balances Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="openingBalancesDatePicker" required formControlName="transactionDate">
            <mat-datepicker-toggle matSuffix [for]="openingBalancesDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #openingBalancesDatePicker></mat-datepicker>
            <mat-error *ngIf="openingBalancesForm.controls.transactionDate.hasError('required')">
              Opening Balances Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" class="content" fxFlex="100%">

            <div class="header" fxFlex="8%">
              Type
            </div>

            <div class="header" fxFlex="18%">
              GL Code
            </div>

            <div class="header" fxFlex="18%">
              Account
            </div>

            <div class="header" fxFlex="23%">
              Total Debit: {{ debitsSum }}
            </div>

            <div class="header" fxFlex="23%">
              Total Credit: {{ creditsSum }}
            </div>

          </div>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" class="content" formArrayName="glAccountEntries"
            *ngFor="let credit of glAccountEntries.controls; let i = index;">

            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" [formGroupName]="i">

              <div fxFlex="8%" class="p-t-30">
                {{ (openingBalancesData.glAccounts[i].glAccountType.value !== openingBalancesData.glAccounts[i-1]?.glAccountType.value) ? openingBalancesData.glAccounts[i].glAccountType.value : '' }}
              </div>

              <div fxFlex="18%" class="p-t-30">
                {{ openingBalancesData.glAccounts[i].glAccountCode }}
              </div>

              <div fxFlex="18%" class="p-t-30">
                {{ openingBalancesData.glAccounts[i].glAccountName }}
              </div>

              <div fxFlex="23%">
                <mat-form-field>
                  <mat-label>Debit</mat-label>
                  <input matInput type="number" formControlName="debit">
                </mat-form-field>
              </div>

              <div fxFlex="23%">
                <mat-form-field>
                  <mat-label>Credit</mat-label>
                  <input matInput type="number" formControlName="credit">
                </mat-form-field>
              </div>

            </div>

          </div>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px" *ngIf="openingBalancesData">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!openingBalancesForm.valid" *mifosxHasPermission="'DEFINEOPENINGBALANCE_JOURNALENTRY'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateSearchFormRef let-popover="popover">
  <h2>Migrate opening balances (Office-wise)</h2>
  <p class="mw300">This form will be used to migrate opening balances. To know more click: <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/90243328/Migrate+opening+balances+Office-wise" target="_blank">Migrate opening balances (Office-wise)</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();popover.close();previousStep();">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();popover.close();nextStep();">Next</button>
  </div>
</ng-template>
