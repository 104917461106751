/** Angular Imports */
import { Component, OnInit } from '@angular/core';

/**
 * Manage Tax Configurations component.
 */
@Component({
  selector: 'mifosx-manage-tax-configurations',
  templateUrl: './manage-tax-configurations.component.html',
})
export class ManageTaxConfigurationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
