<mat-card class="container">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="search-box m-r-30">
      <mat-form-field class="search-box">
        <input matInput placeholder="Search by LAN" class="search-box" (keydown.enter)="search($event.target.value)">
      </mat-form-field>
    </div>
    <!-- <div class="filter-box" *ngIf="existsClientsToFilter">
      <mat-form-field class="filter-box m-r-30">
        <input matInput placeholder="Filter by client name or external Id" class="filter-box" (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
      <mat-checkbox #showClosedAccounts labelPosition="after" class="m-r-10">Show Closed Accounts
      </mat-checkbox>
    </div> -->
    <div class="action-button" fxLayoutGap="25px">
      <!-- <button mat-raised-button color="primary" [routerLink]="['../', 'organization', 'bulk-import', 'Clients']" *mifosxHasPermission="'CREATE_CLIENT'">
        <fa-icon icon="download" class="m-r-10"></fa-icon>Import Client
      </button> -->
      <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_CLIENT'">
        <fa-icon icon="plus" class="m-r-10"></fa-icon>Create Client
      </button>
    </div>
  </div>

  <div [hidden]="!existsClientsToFilter">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- Name Column -->
      <ng-container matColumnDef="entityName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row"> 
          <a [routerLink]="[row.entityId,'general']">
            {{row.entityName}} 
          </a>
        </td>
      </ng-container>

      <!-- Account no Column -->
      <ng-container matColumnDef="entityAccountNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Account No. </th>
        <td mat-cell *matCellDef="let row"> {{row.entityAccountNo}} </td>
      </ng-container>

      <!-- External ID Column -->
      <ng-container matColumnDef="entityExternalId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> External Id </th>
        <td mat-cell *matCellDef="let row">
          <mifosx-external-identifier externalId="{{row.entityExternalId}}"></mifosx-external-identifier>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let row">
          <div [ngClass]="row.entityStatus.code | statusLookup">
            <fa-icon icon="stop"></fa-icon>
            <span class="m-l-10 status">
              {{ row.entityStatus.value | translate }}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Color Column -->
      <ng-container matColumnDef="parentName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Office Name </th>
        <td mat-cell *matCellDef="let row"> {{row.parentName}} </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="staffName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Staff Name </th>
        <td mat-cell *matCellDef="let row"> {{row.staffName}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row">
      </tr>
    </table>

    <mat-paginator [hidden]="!moreClientsToFilter" [pageSize]="50" [pageSizeOptions]="[50, 100]"
      showFirstLastButtons></mat-paginator>
  </div>

  <div class="alert" [hidden]="!notExistsClientsToFilter">
    <div class="message">
      <i class="fa fa-exclamation-circle alert-check"></i>
      No client was found
    </div>
  </div>
</mat-card>
