<div class="container">

  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.inputs.Filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Entry ID </th>
        <td mat-cell *matCellDef="let provisioningJournalEntry"> {{ provisioningJournalEntry.id }} </td>
      </ng-container>

      <ng-container matColumnDef="officeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Office </th>
        <td mat-cell *matCellDef="let provisioningJournalEntry"> {{ provisioningJournalEntry.officeName }} </td>
      </ng-container>

      <ng-container matColumnDef="transactionDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Date </th>
        <td mat-cell *matCellDef="let provisioningJournalEntry"> {{ provisioningJournalEntry.transactionDate }} </td>
      </ng-container>

      <ng-container matColumnDef="transactionId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction ID </th>
        <td mat-cell *matCellDef="let provisioningJournalEntry"> {{ provisioningJournalEntry.transactionId }} </td>
      </ng-container>

      <ng-container matColumnDef="glAccountType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let provisioningJournalEntry"> {{ provisioningJournalEntry.glAccountType.value }} </td>
      </ng-container>

      <ng-container matColumnDef="createdByUserName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let provisioningJournalEntry"> {{ provisioningJournalEntry.createdByUserName }} </td>
      </ng-container>

      <ng-container matColumnDef="glAccountCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Account Code </th>
        <td mat-cell *matCellDef="let provisioningJournalEntry"> {{ provisioningJournalEntry.glAccountCode }} </td>
      </ng-container>

      <ng-container matColumnDef="glAccountName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Account Name </th>
        <td mat-cell *matCellDef="let provisioningJournalEntry"> {{ provisioningJournalEntry.glAccountName }} </td>
      </ng-container>

      <ng-container matColumnDef="debit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Debit </th>
        <td mat-cell *matCellDef="let provisioningJournalEntry"><span *ngIf="provisioningJournalEntry.entryType.value === 'DEBIT'"> {{ (provisioningJournalEntry.currency.displaySymbol || provisioningJournalEntry.currency.code) + ' ' + provisioningJournalEntry.amount }} </span></td>
      </ng-container>

      <ng-container matColumnDef="credit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Credit </th>
        <td mat-cell *matCellDef="let provisioningJournalEntry"><span *ngIf="provisioningJournalEntry.entryType.value === 'CREDIT'"> {{ (provisioningJournalEntry.currency.displaySymbol || provisioningJournalEntry.currency.code) + ' ' + provisioningJournalEntry.amount }} </span></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
