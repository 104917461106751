<div class="container">

  <mat-card>

    <form [formGroup]="delinquencyRangeForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Classification</mat-label>
            <input matInput required formControlName="classification">
            <mat-error *ngIf="delinquencyRangeForm.controls.classification.hasError('required')">
              Classification is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Days From</mat-label>
            <input matInput type="number" required formControlName="minimumAgeDays">
            <mat-error *ngIf="delinquencyRangeForm.controls.minimumAgeDays.hasError('required')">
              Days From is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Days Till</mat-label>
            <input matInput type="number" formControlName="maximumAgeDays">
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!delinquencyRangeForm.valid" *mifosxHasPermission="'UPDATE_DELINQUENCY_RANGE'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
