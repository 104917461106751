<div class="container">

  <mat-card>

    <form [formGroup]="bucketForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="100%">
            <mat-label>Name</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="bucketForm.controls.name.hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <h3 fxFlex="40%" class="mat-h3">Delinquency Ranges</h3>

          <div fxFlex="40%" fxLayout="row" fxLayoutAlign="start center">
            <button type="button" mat-raised-button color="primary" (click)="addDelinquencyRange()">
              <fa-icon icon="plus" class="m-r-10"></fa-icon>
              Add
            </button>
          </div>

          <table mat-table [dataSource]="rangesDataSource" [hidden]="rangesDataSource.length === 0">

            <ng-container matColumnDef="classification">
              <th mat-header-cell *matHeaderCellDef> Classification </th>
              <td mat-cell *matCellDef="let row">
                {{ row.rangeId | find: delinquencyRangesData:'id':'classification'}} </td>
            </ng-container>

            <ng-container matColumnDef="minimumAgeDays">
              <th mat-header-cell *matHeaderCellDef> Days From </th>
              <td mat-cell *matCellDef="let row">
                {{ row.rangeId | find: delinquencyRangesData:'id':'minimumAgeDays'}} </td>
            </ng-container>

            <ng-container matColumnDef="maximumAgeDays">
              <th mat-header-cell *matHeaderCellDef> Days Till </th>
              <td mat-cell *matCellDef="let row">
                {{ row.rangeId | find: delinquencyRangesData:'id':'maximumAgeDays'}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> Actions </th>
              <td mat-cell *matCellDef="let row; let rowIndex = index">
                <button type="button" mat-icon-button color="warn"
                  (click)="deleteDelinquencyRange(rowIndex)" matTooltip="Delete"
                  matTooltipPosition="left">
                  <fa-icon icon="trash"></fa-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          </table>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!bucketForm.valid" *mifosxHasPermission="'CREATE_DELINQUENCY_BUCKET'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
