<div class="container">

  <mat-card>

    <form [formGroup]="periodicAccrualsForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row">

          <mat-form-field fxFlex (click)="tillDatePicker.open()">
            <mat-label>Accrue Till Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="tillDatePicker" required formControlName="tillDate">
            <mat-datepicker-toggle matSuffix [for]="tillDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #tillDatePicker></mat-datepicker>
            <mat-error *ngIf="periodicAccrualsForm.controls.tillDate.hasError('required')">
              Accrue Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!periodicAccrualsForm.valid" *mifosxHasPermission="'EXECUTE_PERIODICACCRUALACCOUNTING'">
          <fa-icon icon="play" class="m-r-10"></fa-icon>
          Run Periodic Accruals
        </button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
