<div class="container m-b-20 m-t-20" fxLayout="row" fxLayoutGap="20px">
    <div #schedulerStatus>
    <h2 class="no-m">Scheduler Status: {{ schedulerActive ? 'Active' : 'Inactive' }}</h2>
    </div>
    <button mat-raised-button class="suspend" (click)="suspendScheduler()" *ngIf="schedulerActive">
      <fa-icon icon="times-circle" class="m-r-10"></fa-icon>
      De-Activate
    </button>
    <button mat-raised-button class="activate" (click)="activateScheduler()" *ngIf="!schedulerActive">
      <fa-icon icon="times-circle" class="m-r-10"></fa-icon>
      Activate
    </button>
    <div>
      <a href="http://www.cronmaker.com/">	Click Here To Generate Cron Expression </a>
    </div>
    <button mat-raised-button color="primary" (click)="runAllJob()">
      <fa-icon icon="play"></fa-icon>
      Smart Run all
    </button>
</div>

<div class="container">

  <!-- <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.inputs.Filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div> -->

  <div #jobsTable class="mat-elevation-z8 space-top">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <!-- <mat-checkbox class="m-r-10" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox> -->
        </th>
        <td mat-cell *matCellDef="let row">
          <!-- <mat-checkbox class="m-r-10" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="executionOrder">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order of Execution </th>
        <td mat-cell *matCellDef="let job"> {{ job.taskOrder }} </td>
      </ng-container>

    <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let job"> {{ job.displayName }} </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
        <td mat-cell *matCellDef="let job" class="center">
          <div [className]="job.active === true ? 'currently-running' : 'not-currently-running'">
            <fa-icon matTooltip="{{ job.active === true ? 'Yes' : 'No' }}" matTooltipPosition="right"
              icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="previousRunTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Previous Run </th>
        <td mat-cell *matCellDef="let job"><span *ngIf="job.lastRunHistory">{{
           job.lastRunHistory.jobRunStartTime | dateFormat: 'DD MMM HH:mm:ss'}}</span></td>
      </ng-container>

      <ng-container matColumnDef="previousRunStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let job" class="center">
          <div *ngIf="job.lastRunHistory">
            <fa-icon *ngIf="job.lastRunHistory.status === 'success'" class="success" matTooltip="Successful"
              matTooltipPosition="right" icon="check-circle" size="lg"></fa-icon>
            <fa-icon *ngIf="!(job.lastRunHistory.status == 'success')" class="fail" matTooltip="Failed"
              matTooltipPosition="right" icon="times-circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="currentlyRunning">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Currently Running </th>
        <td mat-cell *matCellDef="let job" class="center">
          <div [className]="job.currentlyRunning === true ? 'currently-running' : 'not-currently-running'">
            <fa-icon matTooltip="{{ job.currentlyRunning === true ? 'Yes' : 'No' }}" matTooltipPosition="right"
              icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="errorLog">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Error Log </th>
        <td mat-cell *matCellDef="let job" class="center">
          <button mat-icon-button class="errorlog"
            *ngIf="!(job.lastRunHistory && job.lastRunHistory.status == 'success')" matTooltip="Error Log"
            matTooltipPosition="right">
            <fa-icon icon="exclamation-circle" size="lg"></fa-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="executionTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Execution Time </th>
        <td mat-cell *matCellDef="let job">
          <span *ngIf="job.lastRunHistory?.jobRunEndTime">
            {{ calculateExecutionTime(job.lastRunHistory.jobRunStartTime, job.lastRunHistory.jobRunEndTime) }}
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.jobId]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[20, 50]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<!-- <div class="container space-top" fxLayout="row" fxLayoutGap="20px">
  <button mat-raised-button color="primary" (click)="runSelectedJobs()">
    <fa-icon icon="play" class="m-r-10"></fa-icon>
    Run Selected Jobs<ng-template #templateSchedulerJobs let-data let-popover="popover">
  <h2>Template</h2>
  <p>This is the template I am trying to use. Is that fine.</p>
  <button (click)="popover.close();nextStepSchedulerJobs()">Next</button>
</ng-template>
  </button>
  <button mat-raised-button color="primary" (click)="refresh()">
    <fa-icon icon="sync" class="m-r-10"></fa-icon>
    Refresh
  </button>
</div> -->

<ng-template #templateSchedulerStatus let-data let-popover="popover">
  <h4>Scheduler Status. Button will be used to change the status.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true)">Next</button>
  </div>
</ng-template>

<ng-template #templateFilter let-data let-popover="popover">
  <h4>Search bar to filter jobs.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateSchedulerStatus,schedulerStatus, 'bottom', true)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateJobsTable,jobsTable, 'top', true)">Next</button>
  </div>
</ng-template>

<ng-template #templateJobsTable let-data let-popover="popover">
  <h4>List of all scheduled batch jobs <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/67895356/Manage+Scheduler+Jobs" target="_blank">Schedular Jobs</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">Next</button>
  </div>
</ng-template>
