<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
<div #buttonCreateLoanProduct class="in-block">
  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_LOANPRODUCT'">
    <fa-icon icon="plus" class="m-r-10"></fa-icon>
    Create Loan Product
  </button>
  </div>
</div>

<div class="container">

  <div #filter fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.inputs.Filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #loanProductsTable class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let loanProduct"> {{ loanProduct.name }} </td>
      </ng-container>

      <ng-container matColumnDef="shortName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Short Name </th>
        <td mat-cell *matCellDef="let loanProduct"> {{ loanProduct.shortName }} </td>
      </ng-container>

      <ng-container matColumnDef="closeDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Expiry Date </th>
        <td mat-cell *matCellDef="let loanProduct"> {{ loanProduct.closeDate  | dateFormat }} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let loanProduct">
          <div [className]="loanProduct.status | statusLookup">
            <fa-icon matTooltip="{{ loanProduct.status === 'loanProduct.active' ? 'Active' : 'Inactive' }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonCreateLoanProduct let-popover="popover">
  <h2>Create loan product</h2>
  <p class="mw300">This option allows you to create new loan product.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true)">Next</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>Search bar to filter loan products.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonCreateLoanProduct,buttonCreateLoanProduct, 'bottom', true)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateLoanProductsTable,loanProductsTable, 'top', true)">Next</button>
  </div>
</ng-template>

<ng-template #templateLoanProductsTable let-popover="popover">
  <h4 class="mw300">List of loan products in the organization. For more details click: <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/102039585/Loan+Products" target="_blank">Loan Products</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true)">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">Next</button>
  </div>
</ng-template>
