<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
    <button mat-raised-button color="primary" [routerLink]="['edit']" >
        <fa-icon icon="edit" class="m-r-10"></fa-icon>
        Edit
    </button>
    <button mat-raised-button color="warn" (click)="deleteCollateral()" >
        <fa-icon icon="trash" class="m-r-10"></fa-icon>
        Delete
    </button>
</div>

<div class="container">

    <mat-card>

        <mat-card-content>

            <div fxLayout="row wrap" class="content">

                <div fxFlex="50%" class="mat-body-strong">
                    Collateral Name
                </div>

                <div fxFlex="50%">
                    {{ collateralData.name }}
                </div>

                <div fxFlex="50%" class="mat-body-strong">
                    Quality/Type
                </div>

                <div fxFlex="50%">
                    {{ collateralData.quality }}
                </div>

                <div fxFlex="50%" class="mat-body-strong">
                    Base Price
                </div>

                <div fxFlex="50%">
                    {{ collateralData.basePrice }}
                </div>

                <div fxFlex="50%" class="mat-body-strong">
                    Base Percentage
                </div>

                <div fxFlex="50%">
                    {{ collateralData.pctToBase }}
                </div>

                <div fxFlex="50%" class="mat-body-strong">
                    Unit Type
                </div>

                <div fxFlex="50%">
                    {{ collateralData.unitType }}
                </div>

                <div fxFlex="50%" class="mat-body-strong">
                    Currency
                </div>

                <div fxFlex="50%">
                    {{ collateralData.currency }}
                </div>

            </div>

        </mat-card-content>

    </mat-card>

</div>
