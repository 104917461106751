import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mifosx-manage-delinquency-buckets',
  templateUrl: './manage-delinquency-buckets.component.html',
  styleUrls: ['./manage-delinquency-buckets.component.scss']
})
export class ManageDelinquencyBucketsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
