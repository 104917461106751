<div class="container">

  <mat-card>

    <div #fundFormRef>

      <form #formRef="ngForm" [formGroup]="fundForm" fxLayout="row" fxLayoutAlign="start baseline" fxLayoutGap="20px"
        (ngSubmit)="addFund()" *mifosxHasPermission="'CREATE_FUND'">

        <mat-form-field fxFlex="calc(90%-20px)">
          <textarea formControlName="name" matInput placeholder="New Fund"></textarea>
            <mat-error *ngIf="fundForm.controls.name.hasError('required')">
                <strong>Required field</strong>
            </mat-error>
        </mat-form-field>

        <div #buttonAddFund fxFlex>
          <button mat-raised-button color="primary" [disabled]="!fundForm.valid">
            <fa-icon icon="plus"></fa-icon>
          </button>
        </div>

      </form>

    </div>

    <mat-grid-list cols="2" rowHeight="50px">

      <mat-grid-tile *ngFor="let fund of fundsData; index as i">

        <input matInput [value]="fund.name">

        <div fxLayout="row" fxLayoutAlign="flex-start">
          <button mat-button color="primary" (click)="editFund(fund.id,fund.name,i)" *mifosxHasPermission="'UPDATE_FUND'">
            <fa-icon icon="edit"></fa-icon>
          </button>
        </div>

      </mat-grid-tile>

    </mat-grid-list>

  </mat-card>

</div>

<ng-template #templateFundFormRef let-popover="popover">
  <h2>Manage Funds</h2>
  <p class="mw300">This form will be used to add fund. If you would like to add fund click on add funds button and fill the fund name then click on + button else click Next or Back. For more details click: <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/67141736/Manage+Funds" target="_blank">Manage Funds</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();">Add Funds</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">Next</button>
  </div>
</ng-template>

