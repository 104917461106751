<mat-card class="group-card">

  <mat-card-header fxLayout="column" class="header">

    <mat-card-title-group class="header-title-group">

      <div class="profile-image-container">
        <div>
          <img mat-card-md-image class="profile-image" src="assets/images/group_placeholder.png">
        </div>
      </div>

      <div class="mat-typography group-card-title">
        <mat-card-title>
          <h3 fxFlex="95%">
            <i class="fa fa-stop" [ngClass]="groupViewData.status.code|statusLookup"
              [matTooltip]="groupViewData.status.value"></i>
            Group Name : {{groupViewData.name}}
          </h3>

          <div fxFlex="5%">
            <button mat-icon-button [matMenuTriggerFor]="groupMenu" aria-label="Group actions" yPosition="below">
              <mat-icon matListIcon class="actions-menu">
                <fa-icon icon="bars" size="sm"></fa-icon>
              </mat-icon>
            </button>
          </div>
        </mat-card-title>

        <mat-card-subtitle>
          <div fxFlex="45%">
            <b>Group :</b> {{groupViewData.name}} | <b>Center Name:</b> {{groupViewData.centerName}} | <b>Staff:</b>
            {{groupViewData.staffName || 'Unassigned'}}<br />
            <b>Office Name :</b> {{groupViewData.officeName}}<br />
            <b>Activation Date :</b> {{(groupViewData.activationDate)?(groupViewData.activationDate | dateFormat) :'Not Activated'}}<br />
            <span *ngIf="!groupViewData.active">
              <b>Closure Date :</b> {{groupViewData.timeline.closedOnDate | dateFormat}}
            </span>
          </div>
          <div  fxFlex="45%" class="group-meeting" fxLayoutAlign="start start">
            <div *ngIf="groupViewData.collectionMeetingCalendar; else unassigned">
              <p>
                Next Meeting on: {{groupViewData.collectionMeetingCalendar?.nextTenRecurringDates[0] | dateFormat}}
                <i class="fa fa-edit" *ngIf="editMeeting" (click)="doAction('Edit Meeting')"></i><br />
                Meeting Frequency: {{groupViewData.collectionMeetingCalendar?.frequency.value | lowercase}}
              </p>
            </div>
            <ng-template #unassigned>
              <div>
                <p>
                  Next Meeting on: Unassigned
                  <i class="fa fa-calendar"></i><br />
                  Meeting Frequency: N/A
                </p>
              </div>
            </ng-template>
          </div>
        </mat-card-subtitle>
      </div>

      <mat-menu #groupMenu="matMenu">

        <span *ngIf="!(groupViewData.status.value==='Active')">
          <button mat-menu-item *mifosxHasPermission="'UPDATE_GROUP'" (click)="doAction('Activate')">
            <mat-icon matListIcon>
              <fa-icon icon="checkSign" size="sm"></fa-icon>
            </mat-icon>
            <span>Activate</span>
          </button>
        </span>
        <button mat-menu-item *mifosxHasPermission="'UPDATE_GROUP'" (click)="doAction('Edit')">
          <mat-icon matListIcon>
            <fa-icon icon="edit" size="sm"></fa-icon>
          </mat-icon>
          <span>Edit</span>
        </button>
        <button mat-menu-item *mifosxHasPermission="'CREATE_CLIENT'" (click)="doAction('Manage Members')">
          <mat-icon matListIcon>
            <fa-icon icon="plus" size="sm"></fa-icon>
          </mat-icon>
          <span>Add Clients</span>
        </button>
        <button mat-menu-item *mifosxHasPermission="'ASSOCIATECLIENTS_GROUP'"
          (click)="doAction('Transfer Clients')">
          <mat-icon matListIcon>
            <fa-icon icon="users" size="sm"></fa-icon>
          </mat-icon>
          <span>Transfer Clients</span>
        </button>
        <button mat-menu-item *mifosxHasPermission="'TRANSFERCLIENTS_GROUP'" (click)="doAction('Manage Members')">
          <mat-icon matListIcon>
            <fa-icon icon="user" size="sm"></fa-icon>
          </mat-icon>
          <span>Manage Members</span>
        </button>

        <button mat-menu-item *ngIf="groupViewData.active" [matMenuTriggerFor]="AccountApplications">
          <mat-icon matListIcon>
            <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
          </mat-icon>
          <span>Applications</span>
        </button>

        <mat-menu #AccountApplications="matMenu">
          <span *ngIf="groupViewData.clientMembers">
            <button mat-menu-item *mifosxHasPermission="'CREATE_LOAN'">Bulk JLG Loan Application</button></span>
          <button mat-menu-item *mifosxHasPermission="'CREATE_SAVINGSACCOUNT'"
            [routerLink]="['savings-accounts', 'create']">Group Saving Application</button>
          <button mat-menu-item *mifosxHasPermission="'CREATE_LOAN'" [routerLink]="['loans-accounts', 'create']">Group
            Loan Application</button>
          <button mat-menu-item [routerLink]="['loans-accounts', 'glim-account', 'create']">GLIM Loan
            Application</button>
          <button mat-menu-item [routerLink]="['savings-accounts', 'gsim-account' , 'create']"> GSIM Application
          </button>
        </mat-menu>

        <button mat-menu-item [matMenuTriggerFor]="More">More</button>
        <mat-menu #More="matMenu">
          <span *ngIf="groupViewData.collectionMeetingCalendar">
            <button mat-menu-item *mifosxHasPermission="'SAVEORUPDATEATTENDANCE_MEETING'"
              (click)="doAction('Attendance')">Attendance</button></span>
          <span *ngIf="!groupViewData.staffId">
            <button mat-menu-item *mifosxHasPermission="'ASSIGNSTAFF_GROUP'" (click)="doAction('Assign Staff')">Assign
              Staff</button></span>
          <span *ngIf="groupViewData.staffId">
            <button mat-menu-item *mifosxHasPermission="'UNASSIGNSTAFF_GROUP'"
              (click)="doAction('Unassign Staff')">Unassign Staff</button></span>
          <span
            *ngIf="!(groupViewData.centerId || groupViewData.collectionMeetingCalendar) && groupViewData.status.value==='Active'">
            <button mat-menu-item *mifosxHasPermission="'CREATE_MEETING'" (click)="doAction('Attach Meeting')">Attach
              Meeting</button></span>
          <button mat-menu-item *mifosxHasPermission="'CLOSE_GROUP'" (click)="doAction('Close')">Close</button>
          <!-- <button mat-menu-item *mifosxHasPermission="'DELETE_GROUP'" (click)="doAction('Delete')">Delete</button> -->
        </mat-menu>
      </mat-menu>
    </mat-card-title-group>

  </mat-card-header>

  <mat-card-content>

    <nav mat-tab-nav-bar class="navigation-tabs">
      <a mat-tab-link [routerLink]="['./general']" routerLinkActive #general="routerLinkActive"
        [active]="general.isActive">
        General
      </a>
      <a mat-tab-link [routerLink]="['./notes']" *mifosxHasPermission="'READ_GROUPNOTE'" routerLinkActive
        #notes="routerLinkActive" [active]="notes.isActive">
        Notes
      </a>
      <a mat-tab-link [routerLink]="['./committee']" routerLinkActive #committee="routerLinkActive"
        [active]="committee.isActive">
        Committee
      </a>
      <span *ngFor="let groupDatatable of groupDatatables">
        <a mat-tab-link *mifosxHasPermission="'READ_' + groupDatatable.registeredTableName"
          [routerLink]="['./datatables',groupDatatable.registeredTableName]" routerLinkActive
          #datatable="routerLinkActive" [active]="datatable.isActive">
          {{groupDatatable.registeredTableName}}
        </a>
      </span>
    </nav>

    <router-outlet></router-outlet>

  </mat-card-content>

</mat-card>
