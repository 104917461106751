<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <div #buttonCreateHoliday class="in-block">
  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_HOLIDAY'">
    <fa-icon icon="plus" class="m-r-10"></fa-icon>
    Create Holiday
  </button>
  </div>

</div>

<div class="container">

  <div #filterRef fxLayout="row" fxLayoutGap="20px">

    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.inputs.Filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
    <mat-form-field>
      <mat-label> Select Office </mat-label>
      <mat-select [formControl]="officeSelector">
        <mat-option *ngFor="let office of officeData" [value]="office.id">
          {{ office.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="mat-elevation-z8" [hidden]="!officeSelector.value">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Holiday Name </th>
        <td mat-cell *matCellDef="let holidays"> {{ holidays.name }} </td>
      </ng-container>

      <ng-container matColumnDef="fromDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
        <td mat-cell *matCellDef="let holidays"> {{ holidays.fromDate  | dateFormat }} </td>
      </ng-container>

      <ng-container matColumnDef="toDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date </th>
        <td mat-cell *matCellDef="let holidays"> {{ holidays.toDate  | dateFormat }} </td>
      </ng-container>

      <ng-container matColumnDef="repaymentsRescheduledTo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Repayments Scheduled To </th>
        <td mat-cell *matCellDef="let holidays"> {{ holidays.reschedulingType === 1 ? 'Next Repayment Date' : holidays.repaymentsRescheduledTo  | dateFormat }} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let holidays"> {{ holidays.status.value }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row" [routerLink]="[row.id]"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonCreateHoliday let-popover="popover">
  <h2>Create Holiday</h2>
  <p class="mw300">This option allows you to create holidays for different offices in your organization.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep();">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilterRef,filterRef,'bottom', true);">Next</button>
  </div>
</ng-template>

<ng-template #templateFilterRef let-popover="popover">
  <h4>Search bar to filter holidays based on different offices.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonCreateHoliday,buttonCreateHoliday,'bottom', true);">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep();">Next</button>
  </div>
</ng-template>
