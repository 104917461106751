<div class="container">

  <mat-card>

    <form [formGroup]="taxComponentForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="taxComponentForm.controls.name.hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Percentage</mat-label>
            <input matInput required formControlName="percentage">
            <mat-error *ngIf="taxComponentForm.controls.name.hasError('required')">
              Percentage is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="taxComponentData.creditAccountType.value">
            <mat-label>Credit Account Type</mat-label>
            <input matInput formControlName="creditAccountType">
          </mat-form-field>

          <mat-form-field *ngIf="taxComponentData.creditAccount.name">
            <mat-label>Credit Account</mat-label>
            <input matInput formControlName="creditAccount">
          </mat-form-field>

          <mat-form-field (click)="startDatePicker.open()">
            <mat-label>Start Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePicker" required formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="taxComponentForm.controls.startDate.hasError('required')">
              Start Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!taxComponentForm.valid" *mifosxHasPermission="'UPDATE_TAXCOMPONENT'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
