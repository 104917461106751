<div class="tab-container mat-typography" *ngIf="showData">

  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="search-box" fxFlex="40%">
      <mat-form-field fxFlex="90%">
        <input matInput placeholder="Filter by name" (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
    </div>
    <div fxFlex="60%">
      <button mat-raised-button color="success" *mifosxHasPermission="'ACTIVATE_CLIENT'"
        (click)="approveClients()">
        <fa-icon icon="check" class="m-r-10"></fa-icon>Approve
      </button>
    </div>
  </div>

  <ng-container *ngFor="let client of groupedClients | keyvalue">

    <h3> {{client.key}} </h3>

    <ng-container *ngIf="client.value.length > 0">

      <table mat-table [dataSource]="client.value|accountsFilter:'clientApproval'">

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle(client.value) : null" [checked]="selection.hasValue() && isAllSelected(client.value)"
              [indeterminate]="selection.hasValue() && !isAllSelected(client.value)" [aria-label]="checkboxLabel(client.value)">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element" class="view-details" [routerLink]="['../../clients', element.id]"> {{ element.displayName }} </td>
        </ng-container>

        <ng-container matColumnDef="accountNumber">
          <th mat-header-cell *matHeaderCellDef> AccountNumber </th>
          <td mat-cell *matCellDef="let element" class="view-details" [routerLink]="['../../clients', element.id]"> {{ element.accountNo }} </td>
        </ng-container>

        <ng-container matColumnDef="staff">
          <th mat-header-cell *matHeaderCellDef> Staff </th>
          <td mat-cell *matCellDef="let element" class="view-details" [routerLink]="['../../clients', element.id]"> {{ element.staffName }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
        </tr>
      </table>

    </ng-container>

  </ng-container>

</div>

<div class="alert" *ngIf="!(showData)">

  <div class="message">
    <i class="fa fa-exclamation-circle alert-check"></i>
    No pending loans for approval.
  </div>

</div>
