<mat-card class="container">

  <div class="back-button">
    <button type="button" color="primary" mat-raised-button [routerLink]="['../../']">
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>Back
    </button>
  </div>

  <iframe [src]="pentahoUrl" frameborder="0" width=100% height="600px;"></iframe>

</mat-card>
