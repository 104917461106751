<div class="container">

  <mat-card>

    <form [formGroup]="transferClientsForm">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Select Client Members for Transfer</mat-label>
            <mat-select required formControlName="clients" multiple>
              <mat-option *ngFor="let member of clientMembers" [value]="{ id: member.id }">
                {{ member.displayName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="transferClientsForm.controls.clients.hasError('required')">
              Office is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-checkbox class="m-t-10 m-b-10" labelPosition="before" formControlName="inheritDestinationGroupLoanOfficer">
            Inherit Group Loan Officer?
          </mat-checkbox>

          <mat-form-field>
            <mat-label>Destination Group</mat-label>
            <input matInput formControlName="destinationGroupId" [matAutocomplete]="groupsAutocomplete">
            <mat-error *ngIf="transferClientsForm.controls.destinationGroupId.hasError('required')">
              Destination Group is <strong>required</strong>
            </mat-error>
          </mat-form-field>
  
        </div>

        <mat-autocomplete autoActiveFirstOption #groupsAutocomplete="matAutocomplete" [displayWith]="displayGroup">
          <mat-option *ngFor="let group of groupsData" [value]="group">
            {{ group.name }}
          </mat-option>
        </mat-autocomplete>

        <div class="mat-table" *ngIf="transferClientsForm.get('destinationGroupId').value">
          <div class="mat-header-row">
            <div class="mat-header-cell">Destination Group Details</div>
          </div>
          <div class="mat-row">
            <div class="mat-cell">Name</div>
            <div class="mat-cell">{{transferClientsForm.get('destinationGroupId').value.name}}</div>
          </div>
          <div class="mat-row">
            <div class="mat-cell">Id</div>
            <div class="mat-cell">{{transferClientsForm.get('destinationGroupId').value.id}}</div>
          </div>
          <div class="mat-row">
            <div class="mat-cell">Activation Date</div>
            <div class="mat-cell">{{(transferClientsForm.get('destinationGroupId').value.activationDate  | dateFormat) || 'Not Activated'}}</div>
          </div>
          <div class="mat-row">
            <div class="mat-cell">Staff</div>
            <div class="mat-cell">{{transferClientsForm.get('destinationGroupId').value.staffName || 'Unassigned'}}</div>
          </div>
          <div class="mat-row">
            <div class="mat-cell">External Id</div>
            <div class="mat-cell">{{transferClientsForm.get('destinationGroupId').value.externalId || 'Unassigned'}}</div>
          </div>
        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!transferClientsForm.valid" (click)="submit()"
          *mifosxHasPermission="'TRANSFERCLIENTS_GROUP'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
