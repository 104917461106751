<div class="container">

  <mat-card>

    <form [formGroup]="reactivateClientForm" (ngSubmit)="submit()">

      <mat-card-content>

          <mat-form-field fxFlex (click)="reactivationDatePicker.open()">
            <mat-label>Re Activation On Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="reactivationDatePicker" required formControlName="reactivationDate">
            <mat-datepicker-toggle matSuffix [for]="reactivationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #reactivationDatePicker></mat-datepicker>
            <mat-error *ngIf="reactivateClientForm.controls.reactivationDate.hasError('required')">
              Reactivation Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!reactivateClientForm.valid">Confirm</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
