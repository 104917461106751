<div class="container m-b-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end" fxLayoutGap="2%">
  <button mat-raised-button color="primary" [routerLink]="['edit']">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    Edit
  </button>
  <button mat-raised-button color="warn" (click)="delete()">
    <fa-icon icon="trash" class="m-r-10"></fa-icon>
    Delete
  </button>
  <button mat-raised-button color="primary" (click)="changeUserPassword()">
    <fa-icon icon="cog" class="m-r-10"></fa-icon>
    Change Password
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          Login Name
        </div>

        <div fxFlex="50%">
          {{ userData.username }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          First Name
        </div>

        <div fxFlex="50%">
          {{ userData.firstname }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          Last Name
        </div>

        <div fxFlex="50%">
          {{ userData.lastname }}
        </div>


        <div fxFlex="50%" class="mat-body-strong" *ngIf="userData.email">
          Email
        </div>

        <div fxFlex="50%" *ngIf="userData.email">
          {{ userData.email }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          Office
        </div>

        <div fxFlex="50%">
          {{ userData.officeName }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          Roles
        </div>

        <div fxFlex="50%">
          <span *ngFor="let role of userData.selectedRoles">
            {{ role.name }}
          </span>
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
