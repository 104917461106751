<form [formGroup]="recurringDepositProductTermsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <h4 fxFlex="98%" class="mat-h4">Deposit Amount</h4>

    <mat-form-field fxFlex="31%">
      <mat-label>Minimum</mat-label>
      <input type="number" matInput formControlName="minDepositAmount">
    </mat-form-field>

    <mat-form-field fxFlex="31%">
      <mat-label>Default</mat-label>
      <input type="number" matInput formControlName="depositAmount" required>
      <mat-error>
        Default Deposit Amount is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="31%">
      <mat-label>Maximum</mat-label>
      <input type="number" matInput formControlName="maxDepositAmount">
    </mat-form-field>

    <mat-divider fxFlex="98%"></mat-divider>

    <mat-form-field fxFlex="48%">
      <mat-label>Interest Compounding Period</mat-label>
      <mat-select formControlName="interestCompoundingPeriodType" required>
        <mat-option *ngFor="let interestCompoundingPeriodType of interestCompoundingPeriodTypeData" [value]="interestCompoundingPeriodType.id">
          {{ interestCompoundingPeriodType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        Interest Compounding Period is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Interest Posting Period</mat-label>
      <mat-select formControlName="interestPostingPeriodType" required>
        <mat-option *ngFor="let interestPostingPeriodType of interestPostingPeriodTypeData" [value]="interestPostingPeriodType.id">
          {{ interestPostingPeriodType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        Interest Posting Period is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Interest Calculated using</mat-label>
      <mat-select formControlName="interestCalculationType" required>
        <mat-option *ngFor="let interestCalculationType of interestCalculationTypeData" [value]="interestCalculationType.id">
          {{ interestCalculationType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        Interest Calculated using is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Days in Year</mat-label>
      <mat-select formControlName="interestCalculationDaysInYearType" required>
        <mat-option *ngFor="let interestCalculationDaysInYearType of interestCalculationDaysInYearTypeData" [value]="interestCalculationDaysInYearType.id">
          {{ interestCalculationDaysInYearType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        Days in Year is <strong>required</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      Previous
    </button>
    <button mat-raised-button matStepperNext>
      Next&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
