<div class="tab-container mat-typography">
  <div fxLayout="row" fxLayoutAlign="start center">

    <h3 fxFlex="90%">Documents</h3>

    <div fxFlex="10%" *mifosxHasPermission="'CREATE_DOCUMENT'">
      <button mat-raised-button class="f-right" color="primary" (click)="uploadDocument()">
        <fa-icon icon="plus" class="m-r-10"></fa-icon>Add
      </button>
    </div>
  </div>

  <table mat-table #documentsTable [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let document"> {{ document.name }} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
      <td mat-cell *matCellDef="let document"> {{ document.description }} </td>
    </ng-container>

    <ng-container matColumnDef="filename">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> File Name </th>
      <td mat-cell *matCellDef="let document"> {{ document.fileName }} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
      <td mat-cell *matCellDef="let document; let index">
        <button class="document-action-button" mat-raised-button color="primary"
          (click)="downloadDocument(document.id)">
          <fa-icon icon="cloud-download-alt"></fa-icon>
        </button>
        <button class="document-action-button" mat-raised-button color="warn"
          (click)="deleteDocument(document.id, document.name)">
          <fa-icon icon="times"></fa-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator *ngIf="entityDocuments && entityDocuments.length > 10" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>

</div>
