<div fxLayout="row wrap" fxLayout.lt-md="column">

  <h2 class="mat-h2" fxFlexFill>{{ savingProduct.name }}</h2>

  <mat-divider fxFlexFill></mat-divider>

  <h3 class="mat-h3" fxFlexFill>Details</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">Short Name:</span>
    <span fxFlex="60%">{{ savingProduct.shortName }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.description">
    <span fxFlex="40%">Description:</span>
    <span fxFlex="60%">{{ savingProduct.description }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>Currency</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">Currency:</span>
    <span fxFlex="60%">{{ savingProduct.currencyCode | find:savingProductsTemplate.currencyOptions:'code':'name' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Decimal Places:</span>
    <span fxFlex="60%">{{ savingProduct.digitsAfterDecimal }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Currency in multiples of:</span>
    <span fxFlex="60%">{{ savingProduct.inMultiplesOf }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>Terms</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">Nominal Annual Interest Rate:</span>
    <span fxFlex="60%">{{ savingProduct.nominalAnnualInterestRate }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Interest Compounding Period:</span>
    <span fxFlex="60%">{{ savingProduct.interestCompoundingPeriodType | find:savingProductsTemplate.interestCompoundingPeriodTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Interest Posting Period:</span>
    <span fxFlex="60%">{{ savingProduct.interestPostingPeriodType | find:savingProductsTemplate.interestPostingPeriodTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Interest Calculated using:</span>
    <span fxFlex="60%">{{ savingProduct.interestCalculationType | find:savingProductsTemplate.interestCalculationTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Days in Year:</span>
    <span fxFlex="60%">{{ savingProduct.interestCalculationDaysInYearType | find:savingProductsTemplate.interestCalculationDaysInYearTypeOptions:'id':'value' }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>Settings</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill *ngIf="savingProduct.minRequiredOpeningBalance">
    <span fxFlex="40%">Minimum Opening Balance:</span>
    <span fxFlex="60%">{{ savingProduct.minRequiredOpeningBalance }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.lockinPeriodFrequency">
    <span fxFlex="40%">Lock-in Period:</span>
    <span fxFlex="60%">{{ savingProduct.lockinPeriodFrequency + ' ' + (savingProduct.lockinPeriodFrequencyType | find:savingProductsTemplate.lockinPeriodFrequencyTypeOptions:'id':'value') }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Apply Withdrawal Fee for Transfers:</span>
    <span fxFlex="60%">{{ savingProduct.withdrawalFeeForTransfers ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.minBalanceForInterestCalculation">
    <span fxFlex="40%">Balance Required for Interest Calculation:</span>
    <span fxFlex="60%">{{ savingProduct.minBalanceForInterestCalculation }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Enforce Minimum Balance:</span>
    <span fxFlex="60%">{{ savingProduct.enforceMinRequiredBalance ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.minRequiredBalance">
    <span fxFlex="40%">Minimum Balance:</span>
    <span fxFlex="60%">{{ savingProduct.minRequiredBalance }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Withhold Tax is Applicable:</span>
    <span fxFlex="60%">{{ savingProduct.withHoldTax ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.withHoldTax">
    <span fxFlex="40%">Tax Group:</span>
    <span fxFlex="60%">{{ savingProduct.taxGroupId | find:savingProductsTemplate.taxGroupOptions:'id':'name' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Is Overdraft Allowed:</span>
    <span fxFlex="60%">{{ savingProduct.allowOverdraft ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.minOverdraftForInterestCalculation">
    <span fxFlex="40%">Minimum Overdraft Required for Interest Calculation:</span>
    <span fxFlex="60%">{{ savingProduct.minOverdraftForInterestCalculation }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.nominalAnnualInterestRateOverdraft">
    <span fxFlex="40%">Nominal Annual Interest for Overdraft:</span>
    <span fxFlex="60%">{{ savingProduct.nominalAnnualInterestRateOverdraft }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.overdraftLimit">
    <span fxFlex="40%">Maximum Overdraft Amount Limit:</span>
    <span fxFlex="60%">{{ savingProduct.overdraftLimit }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">Enable Dormancy Tracking:</span>
    <span fxFlex="60%">{{ savingProduct.isDormancyTrackingActive ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.isDormancyTrackingActive" fxLayout="row wrap" fxLayout.lt-md="column">
    <span fxFlex="40%">Number of Days to Inactive sub-status:</span>
    <span fxFlex="60%">{{ savingProduct.daysToInactive }}</span>
    <span fxFlex="40%">Number of Days to Dormant sub-status:</span>
    <span fxFlex="60%">{{ savingProduct.daysToDormancy }}</span>
    <span fxFlex="40%">Number of Days to Escheat:</span>
    <span fxFlex="60%">{{ savingProduct.daysToEscheat }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.charges.length" fxLayout="row wrap" fxLayout.lt-md="column">

    <h3 class="mat-h3" fxFlexFill>Charges</h3>

    <mat-divider fxFlexFill></mat-divider>

    <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="savingProduct.charges">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.name + ', ' + charge.currency.displaySymbol }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeCalculationType">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.chargeCalculationType.value }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.amount  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeTimeType">
        <th mat-header-cell *matHeaderCellDef> Collected On </th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.chargeTimeType.value }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="chargesDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: chargesDisplayedColumns;"></tr>

    </table>

  </div>

  <h3 class="mat-h3" fxFlexFill>Accounting</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">Type:</span>
    <span fxFlex="60%">{{ accountingRuleData[savingProduct.accountingRule-1] }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.accountingRule === 2" fxLayout="row wrap" fxLayout.lt-md="column">

    <h4 fxFlexFill class="mat-h4">Assets</h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">Saving reference:</span>
      <span fxFlex="60%">{{ savingProduct.savingsReferenceAccountId | find:savingProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">Overdraft portfolio:</span>
      <span fxFlex="60%">{{ savingProduct.overdraftPortfolioControlId | find:savingProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">Liabilities</h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">Saving control:</span>
      <span fxFlex="60%">{{ savingProduct.savingsControlAccountId | find:savingProductsTemplate.accountingMappingOptions.liabilityAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">Savings transfers in suspense:</span>
      <span fxFlex="60%">{{ savingProduct.transfersInSuspenseAccountId | find:savingProductsTemplate.accountingMappingOptions.liabilityAccountOptions:'id':'name' }}</span>
      <div fxFlexFill *ngIf="savingProduct.isDormancyTrackingActive">
        <span fxFlex="40%">Escheat liability:</span>
        <span fxFlex="60%">{{ savingProduct.escheatLiabilityId | find:savingProductsTemplate.accountingMappingOptions.liabilityAccountOptions:'id':'name' }}</span>
      </div>
    </div>

    <h4 fxFlexFill class="mat-h4">Expenses</h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">Interest on savings:</span>
      <span fxFlex="60%">{{ savingProduct.interestOnSavingsAccountId | find:savingProductsTemplate.accountingMappingOptions.expenseAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">Write-off:</span>
      <span fxFlex="60%">{{ savingProduct.writeOffAccountId | find:savingProductsTemplate.accountingMappingOptions.expenseAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">Income</h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">Income from fees:</span>
      <span fxFlex="60%">{{ savingProduct.incomeFromFeeAccountId | find:savingProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">Income from penalties:</span>
      <span fxFlex="60%">{{ savingProduct.incomeFromPenaltyAccountId | find:savingProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">Overdraft Interest Income:</span>
      <span fxFlex="60%">{{ savingProduct.incomeFromInterestId | find:savingProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
    </div>

    <div *ngIf="savingProduct.advancedAccountingRules && (savingProduct.paymentChannelToFundSourceMappings?.length || savingProduct.feeToIncomeAccountMappings?.length || savingProduct.penaltyToIncomeAccountMappings?.length)" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

      <h3 class="mat-h3" fxFlexFill>Advanced Accounting Rules</h3>

      <mat-divider fxFlexFill></mat-divider>

      <div *ngIf="savingProduct.paymentChannelToFundSourceMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>Fund Sources for Payment Channels</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="savingProduct.paymentChannelToFundSourceMappings">

          <ng-container matColumnDef="paymentTypeId">
            <th mat-header-cell *matHeaderCellDef> Payment Type </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.paymentTypeId | find:savingProductsTemplate.paymentTypeOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="fundSourceAccountId">
            <th mat-header-cell *matHeaderCellDef> Fund Source </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.fundSourceAccountId | find:savingProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="paymentFundSourceDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: paymentFundSourceDisplayedColumns;"></tr>

        </table>

      </div>

      <div *ngIf="savingProduct.feeToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>Fees to Specific Income Accounts</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="savingProduct.feeToIncomeAccountMappings">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef> Fees </th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.chargeId | find:savingProductsTemplate.chargeOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef> Income Account </th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.incomeAccountId | find:savingProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

      </div>

      <div *ngIf="savingProduct.penaltyToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>Penalties to Specific Income Accounts</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="savingProduct.penaltyToIncomeAccountMappings">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef> Penalty </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.chargeId  | find:savingProductsTemplate.penaltyOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef> Income Account </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.incomeAccountId | find:savingProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

      </div>

    </div>

  </div>

</div>

<div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button matStepperPrevious>
    <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
    Previous
  </button>
  <button mat-raised-button [routerLink]="['../']">
    Cancel
  </button>
  <button mat-raised-button color="primary" (click)="submit.emit()" *mifosxHasPermission="taskPermission">
    Submit
  </button>
</div>
