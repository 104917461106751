<div class="container">

  <div #filter fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.inputs.Filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>

    <div class="action-button" fxLayoutGap="25px">
      <button mat-raised-button color="primary" (click)="applyChanges()"
        [disabled]="!existAnyUpdate" *mifosxHasPermission="'UPDATE_EXTERNAL_EVENT_CONFIGURATION'">
        Apply Changes
      </button>
    </div>
  </div>

  <div #eventsTable class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="eventType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Event Type </th>
        <td mat-cell *matCellDef="let event"> {{ event.type }} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let event">
          <mat-slide-toggle [(ngModel)]="event.enabled" name="{{event.type}}" (click)="toggleStatus(event)">
            <div [className]="event.enabled ? 'enabled' : 'disabled'">
              <span *ngIf="event.enabled">Enabled</span>
              <span *ngIf="!event.enabled">Disabled</span>
            </div>
          </mat-slide-toggle>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
