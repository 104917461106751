<div class="container">

  <mat-card>

    <form [formGroup]="editClientForm">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>Office</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeOptions" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="editClientForm.controls.officeId.hasError('required')">
              Office is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Legal Form</mat-label>
            <mat-select formControlName="legalFormId">
              <mat-option *ngFor="let legalForm of legalFormOptions" [value]="legalForm.id">
                {{ legalForm.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Account No</mat-label>
            <input matInput formControlName="accountNo">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Staff</mat-label>
            <mat-select formControlName="staffId">
              <mat-option *ngFor="let staff of staffOptions" [value]="staff.id">
                {{ staff.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="editClientForm.contains('fullname')" fxFlex="48%">
            <mat-label>{{ editClientForm.value.legalFormId === 1 ? 'Name' : 'Entity Name' }}</mat-label>
            <input matInput required formControlName="fullname">
            <mat-error *ngIf="editClientForm.controls.fullname.hasError('required')">
              Client name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="editClientForm.contains('firstname')" fxFlex="48%">
            <mat-label>First Name</mat-label>
            <input matInput required formControlName="firstname">
            <mat-error *ngIf="editClientForm.controls.firstname.hasError('required')">
              Client first name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="editClientForm.contains('middlename')" fxFlex="48%">
            <mat-label>Middle Name</mat-label>
            <input matInput formControlName="middlename">
          </mat-form-field>

          <mat-form-field *ngIf="editClientForm.contains('lastname')" fxFlex="48%">
            <mat-label>Last Name</mat-label>
            <input matInput required formControlName="lastname">
            <mat-error *ngIf="editClientForm.controls.lastname.hasError('required')">
              Client last name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="dateOfBirthDatePicker.open()">
            <mat-label>{{ editClientForm.value.legalFormId === 1 ? 'Date of Birth' : 'Incorporation Date' }}</mat-label>
            <input matInput [max]="maxDate" [matDatepicker]="dateOfBirthDatePicker" formControlName="dateOfBirth">
            <mat-datepicker-toggle matSuffix [for]="dateOfBirthDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #dateOfBirthDatePicker></mat-datepicker>
          </mat-form-field>

          <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" formGroupName="clientNonPersonDetails"
            *ngIf="editClientForm.contains('clientNonPersonDetails')">

            <mat-form-field fxFlex="48%">
              <mat-label>Constitution</mat-label>
              <mat-select formControlName="constitutionId" required>
                <mat-option *ngFor="let constitution of constitutionOptions" [value]="constitution.id">
                  {{ constitution.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="48%">
              <mat-label>Main Business Line</mat-label>
              <mat-select formControlName="mainBusinessLineId">
                <mat-option *ngFor="let business of businessLineOptions" [value]="business.id">
                  {{ business.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="48%" (click)="incorpValidityTillDateDatePicker.open()">
              <mat-label>Incorporation Validity Till Date</mat-label>
              <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="incorpValidityTillDateDatePicker" formControlName="incorpValidityTillDate">
              <mat-datepicker-toggle matSuffix [for]="incorpValidityTillDateDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #incorpValidityTillDateDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="48%">
              <mat-label>Incorporation No</mat-label>
              <input matInput formControlName="incorpNumber">
            </mat-form-field>

            <mat-form-field fxFlex="98%">
              <mat-label>Remarks</mat-label>
              <textarea matInput formControlName="remarks" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
            </mat-form-field>
          </div>

          <mat-form-field fxFlex="48%">
            <mat-label>External Id</mat-label>
            <input matInput formControlName="externalId">
          </mat-form-field>

          <mat-checkbox *ngIf="editClientForm.value.legalFormId === 1" fxFlex="48%" labelPosition="before" formControlName="isStaff" class="margin-v">
            Is staff?
          </mat-checkbox>

          <mat-form-field fxFlex="48%">
            <mat-label>Mobile No</mat-label>
            <input matInput type="number" formControlName="mobileNo">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Email Address</mat-label>
            <input matInput formControlName="emailAddress">
            <mat-error *ngIf="editClientForm.controls.emailAddress.errors?.email">
              Email not valid
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Client Type</mat-label>
            <mat-select formControlName="clientTypeId">
              <mat-option *ngFor="let clientType of clientTypeOptions" [value]="clientType.id">
                {{ clientType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Client Classification</mat-label>
            <mat-select formControlName="clientClassificationId">
              <mat-option *ngFor="let clientClassification of clientClassificationTypeOptions" [value]="clientClassification.id">
                {{ clientClassification.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="submittedOnDatePicker.open()">
            <mat-label>Submitted On</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="submittedOnDatePicker" formControlName="submittedOnDate" required>
            <mat-datepicker-toggle matSuffix [for]="submittedOnDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #submittedOnDatePicker></mat-datepicker>
            <mat-error *ngIf="editClientForm.controls.submittedOnDate.hasError('required')">
              Submitted Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="activatedOnDatePicker.open()">
            <mat-label>Activated On</mat-label>
            <input matInput [min]="editClientForm.value.submittedOnDate" [max]="maxDate" [matDatepicker]="activatedOnDatePicker"
              formControlName="activationDate">
            <mat-datepicker-toggle matSuffix [for]="activatedOnDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #activatedOnDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field *ngIf="editClientForm.value.legalFormId === 1" fxFlex="48%">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="genderId">
              <mat-option *ngFor="let gender of genderOptions" [value]="gender.id">
                {{ gender.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../general']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!editClientForm.valid" (click)="submit()">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
