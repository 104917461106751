<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div #buttonCreateCode>
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_CODE'">
      <fa-icon icon="plus" class="m-r-10"></fa-icon>
      Create Code
    </button>
  </div>
</div>

<div class="container">

  <div #filter fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.inputs.Filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #tableCodes class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Code Name </th>
        <td mat-cell *matCellDef="let code"> {{ code.name }} </td>
      </ng-container>

      <ng-container matColumnDef="systemDefined">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> System Defined </th>
        <td mat-cell *matCellDef="let code">
          <fa-icon *ngIf="code.systemDefined" icon="check-circle" size="lg" class="system-defined" matTooltip="Yes" matTooltipPosition="right"></fa-icon>
          <fa-icon *ngIf="!code.systemDefined" icon="times-circle" size="lg" class="not-system-defined" matTooltip="No" matTooltipPosition="right"></fa-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>
</div>

<ng-template #templateButtonCreateCode let-popover="popover">
  <h2>Manage Codes</h2>
  <p class="mw300">To create additional codes.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep();">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true);">Next</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>Search bar to filter codes.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonCreateCode, buttonCreateCode, 'bottom', true);">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateTableCodes, tableCodes, 'top', true);">Next</button>
  </div>
</ng-template>

<ng-template #templateTableCodes let-popover="popover">
  <h4>List of all codes in the Mifos system for your organization.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true);">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">Next</button>
  </div>
</ng-template>
