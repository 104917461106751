<div class="container">

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="datasource">
      <ng-container matColumnDef="entitymapping">
        <th mat-header-cell *matHeaderCellDef> Mapping Between Entities </th>
        <td mat-cell *matCellDef="let mappingType" (click)="showFilters(mappingType.id)">
          <span *ngIf="mappingType.mappingTypes == 'office_access_to_loan_products'">
            Offices --> Loan Products
          </span>
          <span *ngIf="mappingType.mappingTypes == 'office_access_to_savings_products'">
            Offices --> Savings Products
          </span>
          <span *ngIf="mappingType.mappingTypes == 'office_access_to_fees/charges'">
            Offices --> Charges/Fees
          </span>
          <span *ngIf="mappingType.mappingTypes == 'role_access_to_loan_products'">
            Roles --> Loan Products
          </span>
          <span *ngIf="mappingType.mappingTypes == 'role_access_to_savings_products'">
            Roles --> Savings Products
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row"></tr>
    </table>

    <!---------------- SHOW FILTERING OPTION  ----------->
    <ng-container *ngIf="selectedMappingType > 0">

      <mat-card>

        <form [formGroup]="filterPreferenceForm" (ngSubmit)="showFilteredData()">
          <mat-card-title> {{firstMappingEntity}} access to {{secondMappingEntity}} </mat-card-title>
          <mat-card-content>

            <div fxLayout="row" fxLayoutAlign="space-evenly center">

              <mat-form-field>
                <mat-label>{{firstMappingEntity}}</mat-label>
                <mat-select required formControlName="mappingFirstParamId">
                  <mat-option [value]="0">
                    All
                  </mat-option>
                  <mat-option *ngFor="let firstEntity of firstEntityData" [value]="firstEntity.id">
                    {{ firstEntity.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="filterPreferenceForm.controls.mappingFirstParamId.hasError('required')">
                  {{firstMappingEntity}} is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{secondMappingEntity}}</mat-label>
                <mat-select required formControlName="mappingSecondParamId">
                  <mat-option [value]="0">
                    All
                  </mat-option>
                  <mat-option *ngFor="let secondEntity of secondEntityData" [value]="secondEntity.id">
                    {{ secondEntity.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="filterPreferenceForm.controls.mappingSecondParamId.hasError('required')">
                  {{secondMappingEntity}} is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
                <button mat-raised-button color="primary" [disabled]="!filterPreferenceForm.valid">Submit</button>
                <button *ngIf="hasClickedFilters" mat-raised-button color="primary" (click)="showAddScreen(selectedMappingType)">Add</button>
              </mat-card-actions>
            </div>

          </mat-card-content>
        </form>

        <!----------------- DISPLAY THE MAPPING LIST DATA FOR SELECTED TYPE AND SELECTED ENTITIES -------------------->
        <ng-container *ngIf="hasClickedFilters">
          <div>
            <table mat-table [dataSource]="entityMappingsListData">
              <ng-container matColumnDef="fromentity">
                <th mat-header-cell *matHeaderCellDef> {{firstMappingEntity}} </th>
                <td mat-cell *matCellDef="let listdata"> {{ listdata.fromEntity }} </td>
              </ng-container>

              <ng-container matColumnDef="toentity">
                <th mat-header-cell *matHeaderCellDef> {{secondMappingEntity}} </th>
                <td mat-cell *matCellDef="let listdata"> {{ listdata.toEntity }} </td>
              </ng-container>

              <ng-container matColumnDef="startdate">
                <th mat-header-cell *matHeaderCellDef> Start Date </th>
                <td mat-cell *matCellDef="let listdata"> {{ listdata.startDate }} </td>
              </ng-container>

              <ng-container matColumnDef="enddate">
                <th mat-header-cell *matHeaderCellDef> End Date </th>
                <td mat-cell *matCellDef="let listdata"> {{ listdata.endDate }} </td>
              </ng-container>

              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let listdata">
                  <button type="button" color="primary" mat-icon-button matTooltip="Edit" matTooltipPosition="above" *mifosxHasPermission="'UPDATE_ENTITYMAPPING'"
                    (click)="showEditScreen(listdata.mapId, selectedMappingType)">
                    <fa-icon icon="edit" size="lg"></fa-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let listdata">
                  <button type="button" color="warn" mat-icon-button matTooltip="Delete" matTooltipPosition="above" *mifosxHasPermission="'DELETE_ENTITYMAPPING'"
                  (click)="delete(listdata.mapId)">
                    <fa-icon icon="trash" size="lg"></fa-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="entityMappingListColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: entityMappingListColumns;" class="select-row"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
          </div>
        </ng-container>

      </mat-card>

    </ng-container>
  </div>
</div>
