<mat-card class="container-card" class="m-t-1">
  <mat-card-content>
    <mifosx-manage-scheduler-jobs></mifosx-manage-scheduler-jobs>
    <!-- <mat-tab-group #tabGroup mat-align-tabs="center">
      <mat-tab label="Scheduler Jobs">
      </mat-tab>
      <mat-tab label="Workflow Jobs">
        <mifosx-workflow-jobs></mifosx-workflow-jobs>
      </mat-tab>
    </mat-tab-group> -->

  </mat-card-content>
</mat-card>
