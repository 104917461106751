<div class="container">

  <mat-card>

    <form [formGroup]="undoClientTransferForm" (ngSubmit)="submit()">

      <mat-card-content fxLayout="column">

        <mat-form-field (click)="transferDatePicker.open()">
          <mat-label>Transfer Date</mat-label>
          <input matInput [matDatepicker]="transferDatePicker" required formControlName="transferDate">
          <mat-datepicker-toggle matSuffix [for]="transferDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #transferDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex>
          <mat-label>Note</mat-label>
          <textarea matInput formControlName="note" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
        </mat-form-field>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!undoClientTransferForm.valid">Confirm</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
