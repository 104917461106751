<div class="container">

  <mat-card>

    <form [formGroup]="emailConfigurationForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Username</mat-label>
            <input matInput required formControlName="username">
            <mat-error *ngIf="emailConfigurationForm.controls.username.hasError('required')">
              Username is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput required type="{{ passwordInputType }}" formControlName="password">
            <button mat-button *ngIf="emailConfigurationForm.controls.password.value" matSuffix mat-icon-button
              (mousedown)="passwordInputType = 'text'" (mouseup)="passwordInputType = 'password'">
              <fa-icon *ngIf="passwordInputType === 'password'" icon="eye"></fa-icon>
              <fa-icon *ngIf="passwordInputType === 'text'" icon="eye-slash"></fa-icon>
            </button>
            <mat-error *ngIf="emailConfigurationForm.controls.password.hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Host</mat-label>
            <input matInput required formControlName="host">
            <mat-error *ngIf="emailConfigurationForm.controls.host.hasError('required')">
              Host is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Port</mat-label>
            <input matInput type="number" required formControlName="port">
            <mat-error *ngIf="emailConfigurationForm.controls.port.hasError('required')">
              Port is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="useTLS" class="margin-v">
            Use TLS?
          </mat-checkbox>

          <mat-form-field>
            <mat-label>From Email</mat-label>
            <input matInput type="email" required formControlName="fromEmail">
            <mat-error *ngIf="emailConfigurationForm.controls.fromEmail.hasError('required')">
              From Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>From Name</mat-label>
            <input matInput required formControlName="fromName">
            <mat-error *ngIf="emailConfigurationForm.controls.fromName.hasError('required')">
              From Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary"
          [disabled]="!emailConfigurationForm.valid || emailConfigurationForm.pristine">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
