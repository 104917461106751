<div [ngClass]="{
  'sidebar-full': !sidenavCollapsed,
  'sidebar-compact': sidenavCollapsed
  }">

  <div class="navigation-panel" fxLayout="column">

    <!-- TODO: Allow customization by organization -->
    <div class="app-brand" [routerLink]="['/home']">
      <img src="assets/images/MifosX_logo.png" alt="app-logo" class="app-logo">
    </div>

    <div class="app-user">
      <div class="app-user-photo">
        <!-- TODO: Decision to be taken on providing this feature -->
        <img src="assets/images/user_placeholder.png" alt="app-user-photo">
      </div>
      <span class="app-user-name mb-05">
        {{ tenantIdentifier }} / {{ username }}
      </span>
      <div #userPanel class="app-user-controls">
        <button class="xs-button" mat-icon-button matTooltip="{{ 'tooltips.Settings' | translate }}" [routerLink]="['/settings']">
          <fa-icon icon="cog"></fa-icon>
        </button>
        <button class="xs-button" mat-icon-button matTooltip="{{ 'tooltips.Sign Out' | translate }}" (click)="logout()">
          <fa-icon icon="sign-out-alt"></fa-icon>
        </button>
      </div>
    </div>

    <div class="app-sidenav">

      <div #frequentlyAccessed>
      <div class="mt-1 mb-1 nav-item-sep">
        <mat-divider></mat-divider>
        <span class="text-muted icon-menu-title">{{ 'labels.menus.Frequently Accessed' | translate }}</span>
      </div>
      </div>
      <div class="icon-menu">
        <div class="icon-menu-item" *ngFor="let activity of mappedActivities">
          <button mat-raised-button [matTooltip]="activity.toolTip" [routerLink]="[activity.path]">
            <fa-icon [icon]="activity.icon" size="lg"></fa-icon>
          </button>
        </div>
      </div>

      <div class="nav-item-sep">
        <mat-divider></mat-divider>
        <span class="text-muted">{{ 'labels.menus.Main Items' | translate }}</span>
      </div>

      <mat-nav-list>
        <!-- <mat-list-item [routerLink]="['/dashboard']" matTooltip="Dashboard">
          <mat-icon matListIcon >
            <fa-icon icon="tachometer-alt" size="sm"></fa-icon>
          </mat-icon>
          <a matLine #dashboard>{{ 'labels.menus.Dashboard' | translate }}</a>
        </mat-list-item> -->
        <mat-list-item [routerLink]="['/navigation']" matTooltip="Navigation">
          <mat-icon matListIcon>
            <fa-icon icon="location-arrow" size="sm"></fa-icon>
          </mat-icon>
          <a matLine #navigation>{{ 'labels.menus.Navigation' | translate }}</a>
        </mat-list-item>
        <!-- <mat-list-item [routerLink]="['/checker-inbox-and-tasks/checker-inbox']" matTooltip="Checker Inbox and Tasks">
          <mat-icon matListIcon>
            <i class="fa fa-check" ></i>
          </mat-icon>
          <a matLine>{{ 'labels.menus.Checker Inbox and Tasks' | translate }}</a>
        </mat-list-item> -->
        <!-- <mat-list-item [routerLink]="['/collections/individual-collection-sheet']" matTooltip="Individual Collection Sheet">
          <mat-icon matListIcon>
            <i class="fa fa-tasks "></i>
          </mat-icon>
          <a matLine>{{ 'labels.menus.Individual Collection Sheet' | translate }}</a>
        </mat-list-item> -->
        <!-- <mat-list-item [routerLink]="['/notifications']" matTooltip="Notifications">
          <mat-icon matListIcon>
            <fa-icon icon="bell" size="sm"></fa-icon>
          </mat-icon>
          <a matLine>{{ 'labels.menus.Notifications' | translate }}</a>
        </mat-list-item>
        <mat-list-item [routerLink]="['/accounting/journal-entries/frequent-postings']" matTooltip="Frequent Postings">
          <mat-icon matListIcon>
            <fa-icon icon="sync" size="sm"></fa-icon>
          </mat-icon>
          <a matLine #frequentPostings>{{ 'labels.menus.Frequent Postings' | translate }}</a>
        </mat-list-item> -->
        <!-- <mat-list-item [routerLink]="['/accounting/journal-entries/create']" matTooltip="Create Journal Entry">
          <mat-icon matListIcon>
            <fa-icon icon="plus" size="sm"></fa-icon>
          </mat-icon>
          <a matLine #createJournalEntry>{{ 'labels.menus.Create Journal Entry' | translate }}</a>
        </mat-list-item>
        <mat-list-item [routerLink]="['/accounting/chart-of-accounts']" matTooltip="Chart Of Accounts">
          <mat-icon matListIcon>
            <fa-icon icon="sitemap" size="sm"></fa-icon>
          </mat-icon>
          <a matLine #chartOfAccounts>{{ 'labels.menus.Chart of Accounts' | translate }}</a>
        </mat-list-item> -->
        <mat-list-item (click)="showKeyboardShortcuts()" matTooltip="Keyboard Shortcuts">
          <mat-icon matListIcon>
            <fa-icon icon="keyboard" size="sm"></fa-icon>
          </mat-icon>
          <a matLine>{{ 'labels.menus.Keyboard Shortcuts' | translate }}</a>
        </mat-list-item>
        <!-- <mat-list-item matTooltip="Help">
          <mat-icon matListIcon (click)="help()">
            <fa-icon icon="question-circle" size="sm"></fa-icon>
          </mat-icon>
          <a matLine>{{ 'labels.menus.Help' | translate }}</a>
        </mat-list-item> -->
      </mat-nav-list>

    </div>

  </div>

</div>


<ng-template #templateLogo let-popover="popover">
  <p class="mw300">This logo will return the user to the welcome page from anywhere in the system..</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateUserPanel,userPanel,'bottom', true);">Next</button>
  </div>
</ng-template>

<ng-template #templateUserPanel let-popover="popover">
  <h2>{{ 'labels.menus.User Panel' | translate }}</h2>
  <p class="mw300">This section contains user name, user photograph, user settings and sign out button.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateLogo,logo,'bottom', true);">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFrequentlyAccessed,frequentlyAccessed,'bottom', true);">Next</button>
  </div>
</ng-template>

<ng-template #templateFrequentlyAccessed let-popover="popover">
  <h2>{{ 'labels.menus.Frequently Accessed' | translate }}</h2>
  <p class="mw300">This contains shortcut to the frequently accessed components.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateUserPanel,userPanel,'bottom', true);">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateDashboard,dashboard,'bottom', true);">Next</button>
  </div>
</ng-template>

<ng-template #templateDashboard let-popover="popover">
  <h2>{{ 'labels.menus.Dashboard' | translate }}</h2>
  <p class="mw300">This will navigate the user to dashboard component.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFrequentlyAccessed,frequentlyAccessed,'bottom', true);">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateNavigation,navigation,'bottom', true);">Next</button>
  </div>
</ng-template>

<ng-template #templateNavigation let-popover="popover">
  <h2>{{ 'labels.menus.Navigation' | translate }}</h2>
  <p class="mw300">This will navigate the user to entity navigation screen.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateDashboard,dashboard,'bottom', true);">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFrequentPostings,frequentPostings,'top', true);">Next</button>
  </div>
</ng-template>

<ng-template #templateFrequentPostings let-popover="popover">
  <h2>{{ 'labels.menus.Frequent Postings' | translate }}</h2>
  <p class="mw300">Shortcut to Journal entry screen with accouting rules in place</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateNavigation,navigation,'bottom', true);">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateCreateJournalEntry,createJournalEntry,'top', true);">Next</button>
  </div>
</ng-template>

<ng-template #templateCreateJournalEntry let-popover="popover">
  <h2>{{ 'labels.menus.Create Journal Entry' | translate }}</h2>
  <p class="mw300">Shortcut to the create journal entry screen.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFrequentPostings,frequentPostings,'top', true);">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateChartOfAccounts,chartOfAccounts,'top', true);">Next</button>
  </div>
</ng-template>

<ng-template #templateChartOfAccounts let-popover="popover">
  <h2>{{ 'labels.menus.Chart of Accounts' | translate }}</h2>
  <p class="mw300">Shortcut to chart of accounts screen to add/modify the ledger accounts.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateCreateJournalEntry,createJournalEntry,'top', true);">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">Next</button>
  </div>
</ng-template>
