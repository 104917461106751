<div class="container">

  <mat-card>

    <form [formGroup]="provisioningEntryForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field fxFlex (click)="datePicker.open()">
            <mat-label>Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="datePicker" required formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="provisioningEntryForm.controls.date.hasError('required')">
              Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <div fxFlex>
            <mat-checkbox labelPosition="before" formControlName="createjournalentries">
              Create Journal Entries
            </mat-checkbox>
          </div>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!provisioningEntryForm.valid" *mifosxHasPermission="'CREATE_PROVISIONING_ENTRIES'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
