<div class="container m-b-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end" fxLayoutGap="2%">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_TAXGROUP'">
    <fa-icon icon="edit" class="m-r-10"></fa-icon>
    Edit
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="33%" class="mat-body-strong">
          Name
        </div>

        <div fxFlex="67%">
          {{ taxGroupData.name }}
        </div>

        <div fxFlex="34%" class="mat-body-strong">
          Tax-Component Name
        </div>

        <div fxFlex="33%" class="mat-body-strong">
          Start Date
        </div>

        <div fxFlex="33%" class="mat-body-strong">
          End Date
        </div>

      </div>

      <div fxLayout="row wrap" class="content"  *ngFor="let taxcomponent of taxGroupData.taxAssociations">

          <div fxFlex="34%">
            {{ taxcomponent.taxComponent.name }}
          </div>

          <div fxFlex="33%">
            {{ taxcomponent.startDate  | dateFormat }}
          </div>

          <div fxFlex="33%" *ngIf="taxcomponent.endDate !== undefined || taxcomponent.endDate !== null">
            {{ taxcomponent.endDate  | dateFormat }}
          </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
