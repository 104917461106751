/** Angular Imports */
import { Component } from '@angular/core';

/**
 * Checker Inbox and Tasks Component
 */
@Component({
  selector: 'mifosx-checker-inbox-and-tasks',
  templateUrl: './checker-inbox-and-tasks.component.html',
  styleUrls: ['./checker-inbox-and-tasks.component.scss']
})
export class CheckerInboxAndTasksComponent {

  constructor() { }

}
