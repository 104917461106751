<div class="mat-typography">

  <h3>Address</h3>

  <div fxLayout="row" fxLayoutAlign="flex-end" class="m-b-20">
    <button mat-raised-button color="primary" (click)="addAddress()">
      <fa-icon icon="plus" class="m-r-10"></fa-icon>Add
    </button>
  </div>

  <mat-accordion *ngIf="clientAddressData.length > 0">

    <mat-expansion-panel *ngFor="let address of clientAddressData; index as i" class="address">

      <mat-expansion-panel-header>

        <mat-panel-title>
          {{ getSelectedValue('addressTypeIdOptions', address.addressTypeId)?.name }}
        </mat-panel-title>

        <mat-panel-description>
          {{ address.relationship }}
        </mat-panel-description>

      </mat-expansion-panel-header>

      <mat-divider></mat-divider>

      <div class="address-actions" fxLayout="row" fxLayoutAlign="flex-end center">
        <button mat-button color="primary" (click)="editAddress(address,i)">
          <fa-icon icon="edit"></fa-icon>
        </button>
        <button mat-button color="warn" (click)="deleteAddress(address,i)">
          <fa-icon icon="trash"></fa-icon>
        </button>
        <mat-slide-toggle [checked]="address.isActive" (change)="toggleAddress(address)"></mat-slide-toggle>
      </div>

      <p>
        <span *ngIf="isFieldEnabled('street')">Street : {{address.street}}<br /></span>
        <span *ngIf="isFieldEnabled('addressLine1')">Address Line 1 : {{address.addressLine1}}<br /></span>
        <span *ngIf="isFieldEnabled('addressLine2')">Address Line 2 : {{address.addressLine2}}<br /></span>
        <span *ngIf="isFieldEnabled('addressLine3')">Address Line 3 : {{address.addressLine3}}<br /></span>
        <span *ngIf="isFieldEnabled('townVillage')">Town / Village : {{address.townVillage}}<br /></span>
        <span *ngIf="isFieldEnabled('city')">City : {{address.city}}<br /></span>
        <span *ngIf="isFieldEnabled('stateProvinceId')">State / Province :
          {{getSelectedValue('stateProvinceIdOptions',address.stateProvinceId)?.name}}<br /></span>
        <span *ngIf="isFieldEnabled('countryId')">Country :
          {{getSelectedValue('countryIdOptions',address.countryId)?.name}}<br /></span>
        <span *ngIf="isFieldEnabled('postalCode')">Postal Code : {{address.postalCode}}<br /></span>
        <span *ngIf="isFieldEnabled('isActive')">Active Status : {{address.isActive}}<br /></span>
      </p>

    </mat-expansion-panel>

  </mat-accordion>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left" class="m-r-10"></fa-icon>
      Previous
    </button>
    <button mat-raised-button matStepperNext>
      Next
      <fa-icon icon="arrow-right" class="m-l-10"></fa-icon>
    </button>
  </div>

</div>
