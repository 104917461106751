<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_CHARGE'">
      <fa-icon icon="plus" class="m-r-10"></fa-icon>
      Create Collateral
    </button>
  </div>

  <div class="container">

    <div fxLayout="row" fxLayoutGap="20px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'labels.inputs.Filter' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
    </div>

    <div class="mat-elevation-z8">

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let collateral"> {{ collateral.name }} </td>
        </ng-container>

        <ng-container matColumnDef="quality">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Quality/Type </th>
          <td mat-cell *matCellDef="let collateral"> {{ collateral.quality }} </td>
        </ng-container>

        <ng-container matColumnDef="basePrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Price </th>
          <td mat-cell *matCellDef="let collateral"> {{ collateral.basePrice }} </td>
        </ng-container>

        <ng-container matColumnDef="basePercentage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Percentage </th>
          <td mat-cell *matCellDef="let collateral"> {{ collateral.pctToBase }} </td>
        </ng-container>

        <ng-container matColumnDef="unitType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Type </th>
          <td mat-cell *matCellDef="let collateral"> {{ collateral.unitType }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

      </table>

      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

    </div>

  </div>
