<h1 mat-dialog-title>Welcome to Mifos</h1>

<mat-dialog-content>

<h3 class="mw600">Following guide tour will help you configure Mifos according to your organization needs.</h3>


<mat-grid-list cols="3" rowHeight="50px">

      <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 1 }">Home Screen Tour</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 2 }">Setup Organization</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 3 }">Setup System</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 4 }">Setup Accounting</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 5 }">Setup Products</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 6 }">Setup Funds and Reports</button>
	  </mat-grid-tile>

</mat-grid-list>

<h3>Progress Bar : 0%</h3>

<mat-progress-bar mode="determinate" value="0"></mat-progress-bar>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="warn" [mat-dialog-close]="{ show: 0 }">Close Config. Wizard</button>
</mat-dialog-actions>
