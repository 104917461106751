/** Angular Imports */
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

/** Custom Services */
import { ProductsService } from "app/products/products.service";
import { SettingsService } from "app/settings/settings.service";

/**
 * Edit Charge component.
 */
@Component({
  selector: "mifosx-edit-charge",
  templateUrl: "./edit-charge.component.html",
  styleUrls: ["./edit-charge.component.scss"],
})
export class EditChargeComponent implements OnInit {
  /** Selected Data. */
  chargeData: any;
  /** Charge form. */
  chargeForm: FormGroup;
  /** Select Income. */
  selectedIncome: any;
  /** Select Time Type. */
  selectedTime: any;
  /** Select Currency Type. */
  selectedCurrency: any;
  /** Select Calculation Type. */
  selectedCalculation: any;
  /** Charge Time Type options. */
  chargeTimeTypeOptions: any;
  /** Charge Calculation Type options. */
  chargeCalculationTypeOptions: any;
  /** Show Penalty. */
  showPenalty = true;
  /** Add Fee Frequency. */
  addFeeFrequency = true;
  /** Show GL Accounts. */
  showGLAccount = false;
  /** Charge Payment Mode. */
  chargePaymentMode = false;
  /** Show Fee Options. */
  showFeeOptions = false;

  /**
   * Retrieves the charge data from `resolve`.
   * @param {ProductsService} productsService Products Service.
   * @param {FormBuilder} formBuilder Form Builder.
   * @param {ActivatedRoute} route Activated Route.
   * @param {Router} router Router for navigation.
   * @param {SettingsService} settingsService Settings Service
   */
  constructor(
    private productsService: ProductsService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService
  ) {
    this.route.data.subscribe((data: { chargesTemplate: any }) => {
      this.chargeData = data.chargesTemplate;
    });
  }

  ngOnInit() {
    this.editChargeForm();
  }

  /**
   * Edit Charge form.
   */
  editChargeForm() {
    this.chargeForm = this.formBuilder.group({
      name: [this.chargeData.name, Validators.required],
      chargeAppliesTo: [
        { value: this.chargeData.chargeAppliesTo.id, disabled: true },
        Validators.required,
      ],
      currencyCode: [this.chargeData.currency.code, Validators.required],
      amount: [this.chargeData.amount, Validators.required],
      gstAmount: [this.chargeData.gstAmount, Validators.required],
      active: [this.chargeData.active],
      penalty: [this.chargeData.penalty],
      chargeTimeType: [this.chargeData.chargeTimeType.id, Validators.required],
      chargeCalculationType: [
        this.chargeData.chargeCalculationType.id,
        Validators.required,
      ],
    });
    const removeAmountCycleFields = () => {
      this.chargeForm.get("amountCycleType") &&
        this.chargeForm.removeControl("amountCycleType");
      this.chargeForm.get("daysInYearType") &&
        this.chargeForm.removeControl("daysInYearType");
      this.chargeForm.get("daysInMonthType") &&
        this.chargeForm.removeControl("daysInMonthType");
    };
    const addAmountCycleFields = () => {
      this.chargeForm.addControl(
        "amountCycleType",
        this.formBuilder.control(
          this.chargeData.amountCycleType.id,
          Validators.required
        )
      );
      switch (this.chargeData.amountCycleType.id) {
        case 4: {
          this.chargeForm.addControl(
            "daysInYearType",
            this.formBuilder.control(
              this.chargeData.daysInYearType.id,
              Validators.required
            )
          );
          break;
        }
        case 3: {
          this.chargeForm.addControl(
            "daysInMonthType",
            this.formBuilder.control(
              this.chargeData.daysInMonthType.id,
              Validators.required
            )
          );
          break;
        }
        default: {
          this.chargeForm.removeControl("daysInYearType");
          this.chargeForm.removeControl("daysInMonthType");
        }
      }
      this.chargeForm
        .get("amountCycleType")
        .valueChanges.subscribe((amountCycleType) => {
          if (amountCycleType === 4) {
            this.chargeForm.addControl(
              "daysInYearType",
              this.formBuilder.control(
                this.chargeData.daysInYearType.id,
                Validators.required
              )
            );
            if (this.chargeForm.get("daysInMonthType")) {
              this.chargeForm.removeControl("daysInMonthType");
            }
          } else if (amountCycleType === 3) {
            this.chargeForm.addControl(
              "daysInMonthType",
              this.formBuilder.control(
                this.chargeData.daysInMonthType.id,
                Validators.required
              )
            );
            if (this.chargeForm.get("daysInYearType")) {
              this.chargeForm.removeControl("daysInYearType");
            }
          } else {
            if (this.chargeForm.get("daysInYearType")) {
              this.chargeForm.removeControl("daysInYearType");
            }
            if (this.chargeForm.get("daysInMonthType")) {
              this.chargeForm.removeControl("daysInMonthType");
            }
          }
        });
    };

    switch (this.chargeData.chargeAppliesTo.value) {
      case "Loan": {
        this.chargeTimeTypeOptions = this.chargeData.loanChargeTimeTypeOptions;
        this.chargeCalculationTypeOptions =
          this.chargeData.loanChargeCalculationTypeOptions;
        this.addFeeFrequency = true;
        this.chargePaymentMode = true;
        this.chargeForm.addControl(
          "chargePaymentMode",
          this.formBuilder.control(
            this.chargeData.chargePaymentMode.id,
            Validators.required
          )
        );
        addAmountCycleFields();
        break;
      }
      case "Savings": {
        this.chargeTimeTypeOptions =
          this.chargeData.savingsChargeTimeTypeOptions;
        this.chargeCalculationTypeOptions =
          this.chargeData.savingsChargeCalculationTypeOptions;
        this.addFeeFrequency = false;
        removeAmountCycleFields();
        break;
      }
      case "Shares": {
        this.chargeTimeTypeOptions = this.chargeData.shareChargeTimeTypeOptions;
        this.chargeCalculationTypeOptions =
          this.chargeData.shareChargeCalculationTypeOptions;
        this.addFeeFrequency = false;
        this.showGLAccount = false;
        this.showPenalty = false;
        removeAmountCycleFields();
        break;
      }
      default: {
        this.chargeCalculationTypeOptions =
          this.chargeData.clientChargeCalculationTypeOptions;
        this.chargeTimeTypeOptions =
          this.chargeData.clientChargeTimeTypeOptions;
        this.showGLAccount = true;
        this.addFeeFrequency = false;
        this.chargeForm.addControl(
          "incomeAccountId",
          this.formBuilder.control(
            this.chargeData.incomeOrLiabilityAccount.id,
            Validators.required
          )
        );
        break;
      }
    }
    if (this.chargeData.taxGroup) {
      this.chargeForm.addControl(
        "taxGroupId",
        this.formBuilder.control(
          { value: this.chargeData.taxGroup.id, disabled: true },
          Validators.required
        )
      );
    } else {
      this.chargeForm.addControl(
        "taxGroupId",
        this.formBuilder.control({ value: "?", disabled: true })
      );
    }
    this.chargeForm
      .get("chargeCalculationType")
      .valueChanges.subscribe((chargeCalculationType) => {
        if (chargeCalculationType === 1) {
          removeAmountCycleFields();
        } else if (chargeCalculationType) {
          addAmountCycleFields();
        }
      });
  }

  /**
   * Get Add Fee Frequency value.
   */
  getFeeFrequency(isChecked: boolean) {
    this.showFeeOptions = isChecked;
    if (isChecked) {
      this.chargeForm.addControl(
        "feeInterval",
        this.formBuilder.control("", Validators.required)
      );
      this.chargeForm.addControl(
        "feeFrequency",
        this.formBuilder.control("", Validators.required)
      );
    } else {
      this.chargeForm.removeControl("feeInterval");
      this.chargeForm.removeControl("feeFrequency");
    }
  }

  /**
   * Submits Edit Charge form.
   */
  submit() {
    const charges = this.chargeForm.value;
    charges.locale = this.settingsService.language.code;
    charges.chargePaymentMode = this.chargeData.chargePaymentMode.id;
    this.productsService
      .updateCharge(this.chargeData.id.toString(), charges)
      .subscribe((response: any) => {
        this.router.navigate(["../"], { relativeTo: this.route });
      });
  }
}
