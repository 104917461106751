<h1 mat-dialog-title>Welcome to Mifos</h1>

<mat-dialog-content>

<h3 class="mw600">Following guide tour will help you configure Mifos according to your organization needs.</h3>

<h3>You have successfully completed the setup of Configuration Wizard.</h3>

<h3>Progress Bar : 100 %</h3>

<mat-progress-bar mode="determinate" value="100"></mat-progress-bar>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="warn" mat-dialog-close>Close Config. Wizard</button>
</mat-dialog-actions>
