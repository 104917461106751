<div class="container">

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="executionId" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Execution ID </th>
        <td mat-cell *matCellDef="let jobHistoryData"> {{ jobHistoryData.executionId }} </td>
      </ng-container>

      <ng-container matColumnDef="run_start_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Start time </th>
        <td mat-cell *matCellDef="let jobHistoryData">{{ jobHistoryData.jobRunStartTime | datetimeFormat }}</td>
      </ng-container>

      <ng-container matColumnDef="run_end_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> End time </th>
        <td mat-cell *matCellDef="let jobHistoryData">{{ jobHistoryData.jobRunEndTime | datetimeFormat }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let jobHistoryData">
          <fa-icon *ngIf="jobHistoryData.status === 'COMPLETED'" class="success" matTooltip="Successful" matTooltipPosition="right" icon="check-circle" size="lg"></fa-icon>
          <fa-icon *ngIf="!(jobHistoryData.status == 'COMPLETED')" class="fail" matTooltip="Failed" matTooltipPosition="right" icon="times-circle" size="lg"></fa-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="error_log">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Error Log </th>
        <td mat-cell *matCellDef="let jobHistoryData">
          <!-- {{ jobHistoryData.jobRunErrorMessage }} -->
          <button mat-icon-button class="errorlog" *ngIf="!(jobHistoryData.status == 'COMPLETED')" matTooltip="Error Log" matTooltipPosition="right" (click)="openError(jobHistoryData.executionId)">
            <fa-icon icon="exclamation-circle" size="lg"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[100]" showFirstLastButtons></mat-paginator>

    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="2%" fxLayout.lt-md="column">
      <button type="button" color="primary" mat-raised-button [routerLink]="['../']">Back</button>
    </div>
    <br/>

  </div>

</div>
