import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SettingsService } from 'app/settings/settings.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';

@Component({
  selector: 'mifosx-tenant-selector',
  templateUrl: './tenant-selector.component.html',
  styleUrls: ['./tenant-selector.component.scss']
})
export class TenantSelectorComponent implements OnInit {

  /** Tenant selector form control. */
  tenantSelector = new FormControl();
  tenants: any = [];

  /**
   * Sets the Tenant Identifier of the application in the selector on initial setup.
   * @param {SettingsService} settingsService Settings Service.
   */
  constructor(private settingsService: SettingsService, private authenticationService: AuthenticationService) {
    this.tenantSelector.setValue(this.settingsService.tenantIdentifier);
  }

  ngOnInit(): void {
    this.authenticationService.getTenants().subscribe((data: any) => {
      data.forEach((e: any) => {
        this.tenants.push(e.tenantIdentifier)
        console.log(this.tenants)
      });
    });
  }

  setTenantIdentifier(): void {
    this.settingsService.setTenantIdentifier(this.tenantSelector.value);
  }

  allowSelection(): boolean {
    return (this.tenants.length > 1);
  }

}
