<div class="container">

  <mat-horizontal-stepper class="mat-elevation-z8" labelPosition="bottom" #clientStepper>

    <ng-template matStepperIcon="number">
      <fa-icon icon="pencil-alt" size="sm"></fa-icon>
    </ng-template>

    <ng-template matStepperIcon="edit">
      <fa-icon icon="pencil-alt" size="sm"></fa-icon>
    </ng-template>

    <ng-template matStepperIcon="done">
      <fa-icon icon="check" size="sm"></fa-icon>
    </ng-template>

    <ng-template matStepperIcon="error">
      <fa-icon icon="exclamation-triangle" size="lg"></fa-icon>
    </ng-template>

    <ng-template matStepperIcon="preview">
      <fa-icon icon="eye" size="sm"></fa-icon>
    </ng-template>

    <mat-step [stepControl]="clientGeneralForm">

      <ng-template matStepLabel>GENERAL</ng-template>

      <mifosx-client-general-step [clientTemplate]="clientTemplate" (legalFormChangeEvent)="legalFormChange($event)"></mifosx-client-general-step>

    </mat-step>

    <mat-step>

      <ng-template matStepLabel>FAMILY MEMBERS</ng-template>

      <mifosx-client-family-members-step #clientFamily [clientTemplate]="clientTemplate"></mifosx-client-family-members-step>

    </mat-step>

    <mat-step *ngIf="clientTemplate.isAddressEnabled">

      <ng-template matStepLabel>ADDRESS</ng-template>

      <mifosx-client-address-step #clientAddress [clientTemplate]="clientTemplate"
        [clientAddressFieldConfig]="clientAddressFieldConfig">
      </mifosx-client-address-step>

    </mat-step>

    <mat-step *ngFor="let datatable of datatables">

      <ng-template matStepLabel>{{datatable.registeredTableName}}</ng-template>

      <mifosx-client-datatable-step [datatableData]="datatable" #dtclient></mifosx-client-datatable-step>

    </mat-step>

    <mat-step *ngIf="areFormvalids()" completed>

      <ng-template matStepLabel>PREVIEW</ng-template>

      <mifosx-client-preview-step
        [clientTemplate]="clientTemplate"
        [clientAddressFieldConfig]="clientAddressFieldConfig"
        [client]="client" (submit)="submit()"
      >
      </mifosx-client-preview-step>

    </mat-step>

  </mat-horizontal-stepper>

</div>
