<div class="container">

  <mat-card>

    <form [formGroup]="editEmployeeForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Office</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="editEmployeeForm.controls.officeId.hasError('required')">
              Office is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput required formControlName="firstname">
            <mat-error *ngIf="editEmployeeForm.controls.firstname.hasError('required')">
              First Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="editEmployeeForm.controls.firstname.hasError('pattern')">
              First Name <strong>cannot</strong> begin with a special character or number
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput required formControlName="lastname">
            <mat-error *ngIf="editEmployeeForm.controls.lastname.hasError('required')">
              Last Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="editEmployeeForm.controls.lastname.hasError('pattern')">
              Last Name <strong>cannot</strong> begin with a special character or number
            </mat-error>
          </mat-form-field>

          <mat-checkbox labelPosition="before" formControlName="isLoanOfficer" class="m-b-10">
            Is Loan Officer
          </mat-checkbox>

          <mat-form-field>
            <mat-label>Mobile Number for SMS</mat-label>
            <input matInput formControlName="mobileNo">
          </mat-form-field>

          <mat-checkbox labelPosition="before" formControlName="isActive">
            Active
          </mat-checkbox>

          <mat-form-field (click)="joiningDatePicker.open()">
            <mat-label>Joining Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="joiningDatePicker" required formControlName="joiningDate">
            <mat-datepicker-toggle matSuffix [for]="joiningDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #joiningDatePicker></mat-datepicker>
            <mat-error *ngIf="editEmployeeForm.controls.joiningDate.hasError('required')">
              Joining Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!editEmployeeForm.valid" *mifosxHasPermission="'UPDATE_STAFF'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
