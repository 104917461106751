<div class="container">

  <mat-card>

    <form [formGroup]="codeForm" (ngSubmit)="submit()">

      <mat-card-content>

          <div #codeFormRef fxLayout="column">

            <mat-form-field>
              <mat-label>Code Name</mat-label>
              <input matInput required formControlName="name">
              <mat-error *ngIf="codeForm.controls.name.hasError('required')">
                Code Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>

          </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!codeForm.valid" *mifosxHasPermission="'CREATE_CODE'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateCodeFormRef let-data let-popover="popover">
  <h2>Create Code</h2>
  <p class="mw400">To create code, click on create code and enter the code name.<br> For more details click: <a href="https://mifosforge.jira.com/wiki/spaces/docs/pages/67895350/Manage+Codes" target="_blank">Manage Codes</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">Close</button>
    <button mat-raised-button color="primary" (click)="popover.close()">Create Code</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">Back</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">Next</button>
  </div>
</ng-template>
