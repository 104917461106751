<div class="container">

  <mat-card>

    <form [formGroup]="taxGroupForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="100%">
            <mat-label>Name</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="taxGroupForm.controls.name.hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <h3 fxFlex="40%" class="mat-h3">Tax Components</h3>

          <div fxFlex="40%" fxLayout="row" fxLayoutAlign="start center">
            <button type="button" mat-raised-button color="primary" (click)="addTaxGroup()">
              <fa-icon icon="plus" class="m-r-10"></fa-icon>
              Add
            </button>
          </div>

          <table mat-table [dataSource]="taxComponentsDataSource" [hidden]="taxComponentsDataSource.length === 0">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let taxComponent">
                {{ taxComponent.taxComponentId | find: taxComponentOptions:'id':'name'}} </td>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef> Start Date </th>
              <td mat-cell *matCellDef="let taxComponent"> {{ taxComponent.startDate  | dateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef> End Date </th>
              <td mat-cell *matCellDef="let taxComponent"> {{ taxComponent.endDate ? (taxComponent.endDate  | dateFormat) : '' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> Actions </th>
              <td mat-cell *matCellDef="let taxComponent; let taxComponentIndex = index">
                <button type="button" mat-icon-button color="primary" (click)="editTaxGroup(taxComponent, taxComponentIndex)"
                  matTooltip="Edit" matTooltipPosition="left">
                  <fa-icon icon="edit"></fa-icon>
                </button>
                <button type="button" mat-icon-button color="warn" *ngIf="taxComponent.isNew"
                  (click)="delete(taxComponentIndex)" matTooltip="Delete Interest Rate Chart"
                  matTooltipPosition="left">
                  <fa-icon icon="trash"></fa-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          </table>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!taxGroupForm.valid" *mifosxHasPermission="'UPDATE_TAXGROUP'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
